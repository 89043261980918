import { useLocation, useNavigate } from 'react-router-dom';

import emailIc from '@/assets/ic/email.svg';
import kakaoIc from '@/assets/ic/kakao.svg';
import appleIc from '@/assets/ic/apple-circle.svg';
import { PATH } from '@/constants/path';
import { useSearchUrl } from '@/hooks/use-search-url';

interface ExistAccountProps {
  existingAccount: {
    email: string;
    sns_type: string;
  };
}

export const ExistAccount = ({ existingAccount }: ExistAccountProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getStateBySearchParams } = useSearchUrl();
  const beforeUrl = getStateBySearchParams('before_url') || '';

  const handleClickBtn = () => {
    const authUrl = [PATH.LOGIN, PATH.REGISTER].find(path => beforeUrl?.includes(path));
    navigate(
      `${PATH.LOGIN}${
        !beforeUrl || beforeUrl === 'undefined' || !authUrl ? '' : `?before_url=${beforeUrl}`
      }`,
    );
  };

  return (
    <>
      <div className="jumbotron mobile-align-left">
        <div className="title">
          {/* // TODO: 이름 안내려옴
           <span>이재이님은</span> */}
          <span>이미 가입한 계정이 있어요.</span>
        </div>
        <span className="sub-text">사용하신 이력 그대로 서비스 이용이 가능해요.</span>
      </div>
      <div className="login-contents-wrap">
        <ul className="result-list-group">
          <li className="list-item">
            <div className="label">아이디</div>
            {/* <!-- 카카오 로그인 연동 정보 --> */}
            {existingAccount?.sns_type === 'email' && (
              <div className="text">
                <img src={emailIc} alt="" />
                {decodeURIComponent(existingAccount?.email)}
              </div>
            )}
            {existingAccount?.sns_type === 'kakao' && (
              <div className="text">
                <img src={kakaoIc} alt="" />
                {decodeURIComponent(existingAccount?.email) ?? '카카오 로그인'}
              </div>
            )}
            {existingAccount?.sns_type === 'apple' && (
              <div className="text">
                <img src={appleIc} alt="" />
                {decodeURIComponent(existingAccount?.email)}
              </div>
            )}
          </li>
          {/* // TODO: mutatePassValidate에서 연락처 정보 안내려주고있음
          <li className="list-item">
            <div className="label">연락처</div>
            <div className="text">010-12**-12**</div>
          </li> */}
        </ul>
        <div className="action-group desktop-action-group">
          <button
            type="button"
            className="btn btn-md btn-filled btn-block"
            onClick={handleClickBtn}
          >
            로그인하러 가기
          </button>
        </div>
      </div>
      {/* <!-- 모바일 바텀 네비게이션 --> */}
      <div className="mobile-bottom-nav">
        <div className="btn-inline-group">
          {/* <!-- 연동하고 시작하기 --> */}
          <button
            type="button"
            className="btn btn-md btn-filled btn-block"
            onClick={handleClickBtn}
          >
            로그인하러 가기
          </button>
        </div>
      </div>
    </>
  );
};
