import { MouseEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DeliveryAddressModal } from '@components/common/molecules/modal/delivery-address-modal';
import { MyInquiryDesktop } from '@components/domain/mypage/inquiry/my-inquiry-desktop';
import { NavigateBtn } from '@components/domain/mypage/navigate/navigate-btn';
import { OrderListDesktop } from '@components/domain/mypage/order-list/order-list-desktop';
import { ReviewListDesktop } from '@components/domain/mypage/review/review-list-desktop';

import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import { PATH } from '@/constants/path';
import useLoginInfo from '@/hooks/use-login-info';
import { Toast } from '@/components/common/molecules/toast';

export const MypageTemplate = () => {
  const [toastState, setToastState] = useState({
    isShow: false,
    message: '',
    subMsgs: [''],
  });
  const { logout, userToken } = useLoginInfo();
  const navigate = useNavigate();
  const { data: userDetailData } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const handleClickModify = () => {
    navigate(PATH.MY_INFO);
  };

  const handleClickAddressModalToggle = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
  };

  const handleClickAddressManage = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    handleClickAddressModalToggle();
  };

  const handleToast = (message: string) => {
    setToastState({
      ...toastState,
      isShow: true,
      message,
    });
  };
  const closeToast = () => setToastState({ ...toastState, isShow: false });
  return (
    <>
      <div className="my-mobile-nav">
        <div className="section-contents">
          <div className="my-info">
            <div className="name-col">
              <span className="name">{userDetailData?.self_detail?.nickname}</span>
              <span>님</span>
            </div>
            <button type="button" className="btn btn-sm btn-outline" onClick={handleClickModify}>
              정보수정
            </button>
          </div>
          {/* <!-- 포인트, 쿠폰 --> */}
          {/* <div className="status-group">
            <div className="status-item">
              <div className="value">1,024 P</div>
              <Link className="link" to="#">
                나의 포인트 &gt;
              </Link>
            </div>
            <div className="status-item">
              <div className="value">7</div>
              <Link className="link" to="#">
                나의 쿠폰 &gt;
              </Link>
            </div>
          </div> */}
          {/* <!-- 이벤트 문구 --> */}
          {/* <div className="callout">🎁 가정의 달 기획전! 지금 YDY 전 제품 최대 50% 할인</div> */}
        </div>
        <div className="section-contents menu-contents">
          {/* <!-- 모바일 마이 메뉴 --> */}
          <ul className="menu-list-group">
            <li className="list-item">
              <div className="label">구매 정보</div>
              <ul className="menu-group">
                <li className="menu-list">
                  <Link to={PATH.CART} state={{ from: 'mypage' }}>
                    장바구니
                  </Link>
                </li>
                <li className="menu-list">
                  <Link to={PATH.ORDER_LIST}>주문/배송 조회</Link>
                </li>
              </ul>
            </li>
            {/* <li className="list-item">
              <div className="label">헤택 정보</div>
              <ul className="menu-group">
                <li className="menu-list">
                  <Link to={PATH.MY_POINT}>나의 포인트</Link>
                </li>
                <li className="menu-list">
                  <Link to={PATH.MY_COUPON}>나의 쿠폰</Link>
                </li>
                <li className="menu-list">
                  <Link to={'#'}>
                    친구 초대 <img src="/assets/ic/MenuBadge.svg" alt="" />
                  </Link>
                </li>
              </ul>
            </li> */}
            <li className="list-item">
              <div className="label">정보 관리</div>
              <ul className="menu-group">
                <li className="menu-list">
                  <Link to={PATH.MY_INQUIRY}>나의 문의</Link>
                </li>
                <li className="menu-list">
                  <Link to={PATH.MY_REVIEW}>나의 리뷰</Link>
                </li>
                <li className="menu-list">
                  <Link to={PATH.MY_INFO}>나의 정보수정</Link>
                </li>
                <li className="menu-list">
                  <Link to={'#'} onClick={handleClickAddressManage}>
                    배송지 관리
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <button
            type="button"
            className="btn btn-md btn-outline btn-block"
            onClick={e => {
              e.preventDefault();
              logout();
            }}
          >
            로그아웃
          </button>
        </div>
      </div>
      <main className="my-main desktop-my-default">
        <div className="my-info">
          <div className="name-col">
            <span className="name">{userDetailData?.self_detail?.nickname}</span>
            <span>님</span>
          </div>
          <button type="button" className="btn btn-sm btn-outline" onClick={handleClickModify}>
            정보수정
          </button>
        </div>
        <section className="desktop-jumbotron section">
          <div className="section-title">
            <div className="label">주문/배송 내역</div>
          </div>
        </section>
        <div className="section-contents">
          <OrderListDesktop offset={0} limit={3} />
          <NavigateBtn path={PATH.ORDER_LIST} />
        </div>
        <div className="section-contents">
          <MyInquiryDesktop limit={3} />
          <NavigateBtn path={PATH.MY_INQUIRY} />
        </div>
        <section className="desktop-jumbotron section">
          <div className="section-title">
            <div className="label">나의 리뷰</div>
          </div>
        </section>
        <div className="section-contents">
          <ReviewListDesktop limit={3} />
          <NavigateBtn path={PATH.MY_REVIEW} />
        </div>
      </main>
      {isAddressModalOpen && (
        <DeliveryAddressModal
          isOpen={isAddressModalOpen}
          handleClickAddressModalToggle={handleClickAddressModalToggle}
          from="mypage"
          handleToast={handleToast}
        />
      )}
      {toastState.isShow && <Toast message={toastState.message} handleClickClose={closeToast} />}
    </>
  );
};
