import { Link, useLocation, useNavigate } from 'react-router-dom';

import { PATH } from '@/constants/path';
import useLoginInfo from '@/hooks/use-login-info';

export const HelpSidebarDesktop = ({
  handleAlert,
}: {
  handleAlert: (alert: {
    isOpen: boolean;
    header: string[];
    body: string[];
    btns: { title: string; handleClick: () => void }[];
  }) => void;
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isLogin } = useLoginInfo();

  const handleClickInquiry = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!isLogin) {
      handleAlert({
        isOpen: true,
        header: [''],
        body: ['로그인이 필요한 서비스에요.', '지금 로그인 하시겠어요?'],
        btns: [
          {
            title: '로그인',
            handleClick: () => {
              navigate(`${PATH.LOGIN}?before_url=${encodeURIComponent(PATH.HELP_INQUIRY_CREATE)}`);
            },
          },
        ],
      });
    } else {
      navigate(PATH.HELP_INQUIRY_CREATE);
    }
  };

  return (
    <>
      <div className="desktop-my-sidebar">
        <div className="title">고객센터</div>
        <ul className="my-group-list">
          <li className="my-group-item">
            <div
              id="link-faq"
              className={`label link-label${pathname.includes(PATH.HELP_FAQ) ? ' active' : ''}`}
            >
              <Link to={PATH.HELP_FAQ}>자주하는 질문</Link>
            </div>
          </li>
          <li className="my-group-item">
            <div
              id="link-one"
              className={`label link-label${
                pathname.includes(PATH.HELP_INQUIRY_CREATE) ? ' active' : ''
              }`}
            >
              <Link to="#" onClick={handleClickInquiry}>
                1:1 문의
              </Link>
            </div>
          </li>
          <li className="my-group-item">
            <div
              id="link-notice"
              className={`label link-label${pathname.includes(PATH.HELP_NOTICE) ? ' active' : ''}`}
            >
              <Link to={PATH.HELP_NOTICE}>공지사항</Link>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
