import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import { useFetchGetUserInfo } from '@/apis/friendly-pharmacist/user/useFetchGetUserInfo';
import { WoPreorderGetRes } from '@/generated/api/model';
import useLoginInfo from '@/hooks/use-login-info';
import React, { Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from 'react';

export const Tosspayments = ({
  paymentWidget,
  orderFormData,
  setPaymentWidget,
}: {
  paymentWidget: any;
  orderFormData: WoPreorderGetRes | undefined;
  setPaymentWidget: Dispatch<SetStateAction<any>>;
}) => {
  const { userToken } = useLoginInfo();
  const { data: userInfoData } = useFetchGetUserInfo({
    accessToken: userToken ?? '',
    options: { enabled: !!userToken },
  });
  const { data: userDetailData } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const widgetClientKey = process.env.REACT_APP_TOSSPAYMENTS_KEY ?? '';
  const customerKey =
    userInfoData?.user_info?.id ?? userDetailData?.self_detail?.email ?? 'no_unique_key';

  const tossPaymentsInit = () => {
    const script = document.createElement('script');
    script.src = 'https://js.tosspayments.com/v1/payment-widget';
    script.async = true;
    // 스크립트 로드 완료 후 처리
    script.onload = () => {
      const tossPayment = window?.PaymentWidget;
      if (tossPayment) {
        const widget = tossPayment(widgetClientKey, customerKey);
        setPaymentWidget(widget); // 상태 업데이트
      }
    };

    // head에 스크립트를 추가
    document.head.appendChild(script);

    // 컴포넌트 언마운트 시 스크립트 제거
    return () => {
      document.head.removeChild(script);
    };
  };

  useLayoutEffect(() => {
    tossPaymentsInit();
  }, [widgetClientKey, customerKey]);

  useLayoutEffect(() => {
    const renderWidget = async () => {
      if (paymentWidget && orderFormData?.order) {
        // renderPaymentMethods의 반환값(인스턴스)를 paymentInstance에 저장
        await paymentWidget.renderPaymentMethods(
          '#payment-method',
          {
            value: orderFormData?.order?.total_payment_amount,
            currency: 'KRW',
            country: 'KR',
          },
          { variantKey: 'DEFAULT' },
        );
      }
    };
    renderWidget();
  }, [paymentWidget, orderFormData]);

  return (
    <div className="wrapper w-100">
      <div className="max-w-540 w-100">
        <div id="payment-method" className="w-100"></div>
        <div id="agreement" className="w-100"></div>
      </div>
      {/* {errorMessage !== null && <Index message={errorMessage} duration={1500} />} */}
    </div>
  );
};
