import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoOrderStatusGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  orderIdx: string;
  options?: QueryOptionsType<WoOrderStatusGetRes>;
}

export function useFetchOrderStatus({ orderIdx, options }: Props) {
  const getOrderDetail = async () => {
    return await get(`/api/commerce/v1/orders/${orderIdx}/status`).then(res => res.data);
  };

  return useQuery({
    queryKey: ['useFetchOrderStatus', orderIdx],
    queryFn: getOrderDetail,
    ...options,
  });
}
