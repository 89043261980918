import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import redDot from '@/assets/ic/reddot.svg';
import myPageIc from '@/assets/ic/side-my-bk.svg';
import recentlySeenIC from '@/assets/ic/side-my-view.svg';
import cartIc from '@/assets/ic/side-prduct-bag.svg';
import trackingIc from '@/assets/ic/side-truck-bk.svg';
import closeIc from '@/assets/ic/x-lg.svg';
import eventImg from '@/assets/img/event.png';
import { PATH } from '@/constants/path';
import useLoginInfo from '@/hooks/use-login-info';
import { useFetchMenu } from '@/apis/commerce/menus/useFetchMenu';
import { AlertModal } from '@/components/common/molecules/modal/alert-modal';

interface SnbProps {
  snbToggle: () => void;
}
/**
 * Side Navigation Bar (모바일에서 햄버거 클릭시 토글)
 * 리스트는 gnb와 동일
 */
export const Snb = ({ snbToggle }: SnbProps) => {
  const [alert, setAlert] = useState({
    isOpen: false,
    header: [''],
    body: [''],
    btns: [{ title: '', handleClick: () => {} }],
  });
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const beforeUrl = encodeURIComponent(`${pathname}${search ? search : ''}`);
  const { isLogin, logout, userToken } = useLoginInfo();
  const { data: userDetailData } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const [toggleItem, setToggleItem] = useState('');
  const { data: menuData } = useFetchMenu({});

  const toggle = (item: string) => {
    toggleItem === item ? setToggleItem('') : setToggleItem(item);
  };
  const handleClickNavigate = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    snbToggle();
    navigate(e.currentTarget.pathname);
  };
  const handleClickInquiry = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!isLogin) {
      setAlert({
        isOpen: true,
        header: [''],
        body: ['로그인이 필요한 서비스에요.', '지금 로그인 하시겠어요?'],
        btns: [
          {
            title: '로그인',
            handleClick: () => {
              navigate(`${PATH.LOGIN}?before_url=${encodeURIComponent(PATH.HELP_INQUIRY_CREATE)}`);
              snbToggle();
            },
          },
        ],
      });
    } else {
      navigate(PATH.HELP_INQUIRY_CREATE);
      snbToggle();
    }
  };
  const handleClickLogout = (e: React.MouseEvent<HTMLButtonElement>) => {
    snbToggle();
    logout();
  };

  return (
    <>
      {alert.isOpen && (
        <AlertModal isOpen={true} body={alert.body} buttons={alert.btns} closeAlert={() => {}} />
      )}
      <div id="nav-side-drawer" className="left-mobile-nav">
        <div className="title-bar">
          {userDetailData?.self_detail ? (
            <div className="navbar-collapse login">
              <Link to="#" className="user-profile">
                {/* <div className="avatar">
                <img src={userDetail?.profile_img} alt="recently seen product" />
              </div> */}
                <span className="name">{userDetailData?.self_detail?.nickname}</span>
                <span>님</span>
                <span className="arrow"></span>
              </Link>
            </div>
          ) : (
            <div className="navbar-collapse logout">
              <button
                type="button"
                className="btn btn-sm btn-outline"
                onClick={() => {
                  navigate(`${PATH.LOGIN}?before_url=${beforeUrl}`);
                  snbToggle();
                }}
              >
                로그인
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline"
                onClick={() => {
                  navigate(`${PATH.REGISTER}?before_url=${beforeUrl}`);
                  snbToggle();
                }}
              >
                회원가입
              </button>
            </div>
          )}

          {/* <!-- 닫기 기본 --> */}
          <Link className="btn-close" to="#" onClick={snbToggle}>
            <img src={closeIc} alt="close snb" />
          </Link>
        </div>
        <div className="contents-wrap">
          <ul className="short-cut-list">
            <li className="list-item">
              <Link to={PATH.MYPAGE} className="item-wrap" onClick={handleClickNavigate}>
                <div className="img-box">
                  <img src={myPageIc} alt="mypage" />
                </div>
                <span className="title">마이</span>
              </Link>
            </li>
            <li className="list-item">
              <Link to={PATH.ORDER_LIST} className="item-wrap" onClick={handleClickNavigate}>
                <div className="img-box">
                  <img src={trackingIc} alt="tracking" />
                  {!!userDetailData?.self_order_status?.order_cnt && (
                    <span className="badge-amount">
                      {userDetailData?.self_order_status?.order_cnt}
                    </span>
                  )}
                </div>
                <span className="title">주문배송</span>
              </Link>
            </li>
            <li className="list-item">
              <Link
                to={PATH.CART}
                className="item-wrap"
                onClick={handleClickNavigate}
                state={{ from: 'snb' }}
              >
                <div className="img-box">
                  <img src={cartIc} alt="cart" />
                  {!!userDetailData?.self_order_status?.cart_cnt && (
                    <span className="badge-amount">
                      {userDetailData?.self_order_status?.cart_cnt}
                    </span>
                  )}
                </div>
                <span className="title">장바구니</span>
              </Link>
            </li>
            {/* <li className="list-item">
              <Link to="#" className="item-wrap" onClick={snbToggle}>
                <div className="img-box">
                  <img src={recentlySeenIC} alt="recently seen product list" />
                </div>
                <span className="title">최근 본</span>
              </Link>
            </li> */}
          </ul>
          <ul className="menu-group-list">
            {menuData?.menus?.map(menu => (
              <li className="menu-group-item" key={menu?.menu1_name}>
                <article>
                  <div
                    onClick={() => toggle(menu?.menu1_name ?? '')}
                    className={`tab-menu${toggleItem === menu?.menu1_name ? ' active' : ''}${
                      (menu?.ord1 ?? 0) === 10000
                        ? ' best'
                        : (menu?.ord1 ?? 0) > 10000
                        ? ' event'
                        : ''
                    }`}
                  >
                    <span>
                      {menu?.menu1_name}
                      {menu?.new_yn === 'Y' && <img className="new" src={redDot} alt="" />}
                    </span>
                  </div>

                  {(menu?.ord1 ?? 0) < 5 && (
                    <ul
                      className={`tab-menu-list${menu?.menu1_name === '브랜드' ? ' brand' : ''}${
                        toggleItem === menu?.menu1_name ? ' show' : ''
                      }`}
                    >
                      {menu?.sub_menus?.map(sub => (
                        <li className="menu-item" key={`${sub?.menu_id}${sub?.menu2_name}`}>
                          <Link
                            to={`/${menu?.collection_type_code}?${sub?.menu_key}=${sub?.menu_id}`}
                            onClick={handleClickNavigate}
                          >
                            {sub?.image_url && menu?.menu1_name === '브랜드' && (
                              <img src={sub?.image_url} alt={sub?.menu2_name} />
                            )}
                            {sub?.menu2_name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                  {(menu?.ord1 ?? 0) > 10000 && (
                    <div className="event-item">
                      <img src={eventImg} alt="event" />
                    </div>
                  )}
                </article>
              </li>
            ))}
            <li className="menu-group-item">
              <article>
                <div
                  onClick={() => toggle('고객센터')}
                  className={`tab-menu${toggleItem === '고객센터' ? ' active' : ''}`}
                >
                  <span>고객센터</span>
                </div>
                <ul className={`tab-menu-list${toggleItem === '고객센터' ? ' show' : ''}`}>
                  <li className="menu-item">
                    <Link to={PATH.HELP_FAQ} onClick={handleClickNavigate}>
                      자주하는 질문
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to="#" onClick={handleClickInquiry}>
                      1:1 문의
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link to={PATH.HELP_NOTICE} onClick={handleClickNavigate}>
                      공지사항
                    </Link>
                  </li>
                </ul>
              </article>
            </li>
          </ul>
          {!!userDetailData?.self_detail && (
            <div className="logout">
              <button
                type="button"
                className="btn btn-md btn-block btn-outline"
                onClick={handleClickLogout}
              >
                로그아웃
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
