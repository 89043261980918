import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { HelpSidebarDesktop } from '@/layouts/help-layout/help-sidebar/help-sidebar-desktop';
import { AlertModal } from '@/components/common/molecules/modal/alert-modal';

export const HelpLayout: FC = () => {
  const [alert, setAlert] = useState({
    isOpen: false,
    header: [''],
    body: [''],
    btns: [{ title: '', handleClick: () => {} }],
  });
  const handleAlert = ({
    isOpen,
    header,
    body,
    btns,
  }: {
    isOpen: boolean;
    header: string[];
    body: string[];
    btns: { title: string; handleClick: () => void }[];
  }) => {
    setAlert({
      isOpen,
      header,
      body,
      btns,
    });
  };
  return (
    <>
      <HelpSidebarDesktop handleAlert={handleAlert} />
      <Outlet />
      {alert.isOpen && (
        <AlertModal isOpen={true} body={alert.body} buttons={alert.btns} closeAlert={() => {}} />
      )}
    </>
  );
};
