import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useMutationOrderSuccess } from '@/apis/commerce/orders/useMutationOrderSuccess';
import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import { PATH } from '@/constants/path';
import useLoginInfo from '@/hooks/use-login-info';
import useMixpanel from '@/hooks/use-mixpanel';
import { useSearchUrl } from '@/hooks/use-search-url';
import { numberFormat } from '@/utils/formats';
import { LoadingEntire } from '@/components/common/organism/auth/loading-entire';
import toast from 'react-hot-toast';

export const OrderResultTemplate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { getStateBySearchParams } = useSearchUrl();
  const { userToken } = useLoginInfo();
  const orderId = getStateBySearchParams('orderId') ?? '';
  // 성공시 들어오는 parameter
  const paymentType = getStateBySearchParams('paymentType') ?? '';
  const paymentKey = getStateBySearchParams('paymentKey') ?? '';
  const paidAmount = getStateBySearchParams('amount') ?? 0;

  // 실패시 들어오는 parameter
  const errorMessage = getStateBySearchParams('message');
  const code = getStateBySearchParams('code');
  const { handleMixpanelEvent } = useMixpanel();
  const {
    data: orderData,
    mutate: orderSuccess,
    isPending: isPendingOrderData,
  } = useMutationOrderSuccess();

  const { data: userDetailData } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  useEffect(() => {
    orderSuccess(
      {
        order_id: orderId,
        payment_type: paymentType,
        payment_key: paymentKey,
        amount: Number(paidAmount),
        payment_detail: '',
      },
      {
        onError: (e: any) => {
          e.response.data && toast.error(e.response.data);
          navigate(`${PATH.ORDER_FAILED}${id ? `?id=${id}` : ''}`, {
            replace: true,
          });
        },
      },
    );
    // eslint-disable-next-line
  }, [orderId]);
  useEffect(() => {
    handleMixpanelEvent('view_payment_complete', {});
  }, []);

  const isFailed = code !== null && errorMessage !== null;
  const isSucceed =
    !!paymentType && !!paymentKey && !!paidAmount && orderData?.order_status?.order_idx;
  const handleClickBtn = () => {
    navigate(PATH.ORDER_LIST);
  };

  return (
    <>
      {isPendingOrderData ? (
        <LoadingEntire />
      ) : (
        isSucceed && (
          <>
            <div className="empty-list cart">
              <div className="lines">
                <span>“결제가 완료되었어요”</span>
                <span className="line"></span>
              </div>
              <div className="sub-text">
                {userDetailData?.self_detail?.nickname}님, 주문하신 상품을 안전하고 빠르게 배송해
                드릴게요.
              </div>
            </div>
            <div className="section-contents">
              <div className="selected-address payment-done">
                <div className="border-wrap payment-done">
                  <div className="row">
                    <div className="label">주문번호</div>
                    <div className="text">{orderData?.order_status?.order_idx}</div>
                  </div>
                  <div className="row address">
                    <div className="label">수령인</div>
                    <div className="text">{orderData?.order_status?.ship_recipient_name}</div>
                  </div>
                  <div className="row address">
                    <div className="label">배송지</div>
                    <div className="text">{orderData?.order_status?.ship_address}</div>
                  </div>
                </div>
              </div>
              <div className="selected-address payment-done">
                <div className="border-wrap payment-done">
                  <div className="row payment-done">
                    <div className="label">최종 결제 금액</div>
                    <div className="text">
                      {numberFormat(orderData?.order_status?.purchase_total_payment_amount ?? 0)}
                      <span className="unit">원</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-contents border-top-0">
              <div className="payment-btn-wrap">
                <button
                  type="button"
                  className="btn btn-md btn-outline btn-block"
                  onClick={handleClickBtn}
                >
                  주문 내역보기
                </button>
              </div>
            </div>

            <div className="cart-notice payment-done">
              <div className="title">유의사항</div>
              <ul className="list-group">
                <li>결제 완료 상품의 경우, “취소 신청 &gt; 환불”로 이루어집니다.</li>
                <li>
                  배송 준비 중 상품의 경우 “취소 요청 &gt; 환불”로 진행되며 이미 상품이 발송된 경우
                  취소가 철회될 수 있습니다.
                </li>
                <li>
                  주문하신 상품이 “배송중” 단계로 넘어간 경우 주문취소가 불가능합니다. 이러한 경우
                  상품 수령 후 반품신청을 하셔야 하는점 유의해 주시길 바랍니다. (고객변심 또는
                  구매자의 귀책 사유로 반품하시는 경우 반품 배송비가 부과되는 점 유의해 주시기
                  바랍니다.)
                </li>
                <li>환불 : 주문취소가 완료되면 결제수단에 따라 환불이 진행됩니다.</li>
                <li>
                  환불 소요기간은 영업일 기준 3~7일 이내 처리되며, 적립금으로 환불받으신 경우
                  적립금으로 환불됩니다.
                </li>
                <li>
                  재고 부족등의 이유로 부득이하게 배송전 자동으로 주문이 취소되어 환불처리 될 수
                  있습니다
                </li>
                <li>
                  기타 궁금한 사항은{' '}
                  <Link className="link" to={PATH.HELP_INQUIRY_CREATE}>
                    1:1 문의
                  </Link>
                  를 이용해 주세요.
                </li>
              </ul>
            </div>
          </>
        )
      )}
    </>
  );
};

// const FAIL_MESSAGES = [
//   { code: 'PAY_PROCESS_CANCELED', message: '결제가 취소되었습니다' },
//   { code: 'PAY_PROCESS_ABORTED', message: '결제가 실패되었습니다' },
//   { code: 'REJECT_CARD_COMPANY', message: '카드에 문제가 있습니다!' },
// ];
// console.log(FAIL_MESSAGES);

/**
 * order_status :{
order_idx: 50185
purchase_discount_card_event_discount: 0
purchase_discount_coupon_used_amount: 0
purchase_discount_points_used_amount: 0
purchase_discount_total: 0
purchase_order_tot_amount: 21370
purchase_shipping_fee: 3000
purchase_total_payment_amount: 24370
refund_payment_type: null
refund_refund_shipping_fee: 0
refund_shipping_fee: 0
refund_total_payment_amount: 0
ship_address: "(06237) 서울특별시 강남구 논현로85길 5 (역삼동)ㄴㄴ"
ship_contact1: "010-1234-1234"
ship_memo: "문 앞에 놓아주세요"
ship_recipient_name: "류가인"
}
 */
