import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';
import {
  AUTH_REFRESH_TOKEN_KEY,
  AUTH_SKIP_401,
  AUTH_TOKEN_KEY,
  AUTH_TOKEN_REFRESH_URL,
  AUTH_USER_INFO_URL,
} from '@/constants/auth';
import { PATH } from '@/constants/path';

const sessionStorageToken = sessionStorage.getItem(AUTH_TOKEN_KEY);
const localStorageToken = localStorage.getItem(AUTH_TOKEN_KEY);

let isRefreshing = false;
let failedQueue: Array<{
  resolve: (value?: unknown) => void;
  reject: (reason?: any) => void;
  config: AxiosRequestConfig;
}> = [];

const processQueue = (error: any | null, token: string | null = null) => {
  failedQueue.forEach(promise => {
    if (error && promise.config.url !== AUTH_TOKEN_REFRESH_URL) {
      promise.reject(error);
    } else {
      promise.resolve(httpClient(promise.config));
    }
  });

  failedQueue = [];
};

const clearTokens = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  sessionStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
  sessionStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);

  delete httpClient.defaults.headers.common['Authorization'];
};

export const httpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL ?? ''}`,
  headers: {
    accept: 'application/json',
    Authorization: `Bearer ${sessionStorageToken ?? localStorageToken ?? ''}`,
  },
  withCredentials:
    !window.location.host.includes('localhost') && !window.location.host.includes('alpha'),
});

httpClient.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete config.headers.Authorization;
    }

    const NEED_AUTH = ['/update', 'agree', 'info', 'confirm', 'withdrawal', 'tokens'];
    if (config.url && (config.url.includes('/user/') || config.url.includes('/auth'))) {
      delete config.withCredentials;
      NEED_AUTH.filter(url => config.url?.includes(url)).length === 0 &&
        delete config.headers.Authorization;
      config.baseURL = process.env.REACT_APP_AUTH_API_URL;
    }

    return config;
  },
  error => Promise.reject(error),
);

/**
 * status code 401,402
 * 두곳의 API는 친한약사에서 전달받은 인증토큰으로 사용합니다.
 * 친한약사 API : 패스인증, 소셜인증, 계정 유효성검사, 계정가입, 로그인, 유저정보
 * 친한스토어 API : 유저정보
 * 401이나 402는 유효하지 않는 토큰으로 요청했을 때 전달됩니다.
 * 401인 경우 token refresh를 통해 401 api를 재호출하고 refresh토큰 만료시 로그아웃 처리합니다.
 * 친한약사와 배포시기가 맞지않아 404, 500 케이스 넣었습니다.
 */
httpClient.interceptors.response.use(
  response => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosRequestConfig & { _retry?: boolean };
    const refreshToken =
      localStorage.getItem(AUTH_REFRESH_TOKEN_KEY) ??
      sessionStorage.getItem(AUTH_REFRESH_TOKEN_KEY);

    if (
      [404, 500].includes(error.response?.status ?? 0) &&
      error.response?.config.url === AUTH_TOKEN_REFRESH_URL
    ) {
      processQueue(null, null);
      isRefreshing = false;
      clearTokens();
      originalRequest._retry = true;
      isRefreshing = true;
      toast.error('로그인이 만료되었습니다.\n 다시 로그인 해주세요.');
      return Promise.reject(error);
    }
    if (
      error.response?.status === 409 &&
      ![PATH.LOGIN, PATH.REGISTER].includes(window.location.pathname)
    ) {
      clearTokens();
      window.location.href = PATH.LOGIN;
      toast.error('정상적인 접근이 아닙니다.\n 다시 로그인 해주세요.');
      return;
    }
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      !AUTH_SKIP_401.find(url => originalRequest.url?.includes(url))
    ) {
      clearTokens();

      if (isRefreshing) {
        if (error.response.config.url === AUTH_TOKEN_REFRESH_URL) {
          isRefreshing = false;
          // Handle refresh token expiration or failure
          originalRequest._retry = true;
          isRefreshing = true;
          toast.error('로그인이 만료되었습니다.\n 다시 로그인 해주세요.');
          return Promise.reject(error);
        }
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject, config: { ...originalRequest } });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        if (refreshToken) {
          const { data } = await httpClient.post(
            AUTH_TOKEN_REFRESH_URL,
            {
              refresh_token: refreshToken,
            },
            {
              baseURL: process.env.REACT_APP_AUTH_API_URL,
            },
          );
          const newToken = data?.data?.access_token;

          // Store new access token
          localStorageToken
            ? localStorage.setItem(AUTH_TOKEN_KEY, newToken)
            : sessionStorage.setItem(AUTH_TOKEN_KEY, newToken);

          originalRequest._retry = true;
          isRefreshing = true;
          // 401
          processQueue(null, newToken);

          isRefreshing = false;

          // Retry the original request
          originalRequest.headers &&
            (originalRequest.headers['Authorization'] = `Bearer ${newToken}`);
          return httpClient(originalRequest);
        } else {
          throw new Error('리프레시 토큰이 없습니다.\n다시 로그인 해주세요');
        }
      } catch (refreshError) {
        isRefreshing = false;
        toast.error('로그인이 만료되었습니다.\n 다시 로그인 해주세요.');
        const { pathname, search } = window.location;
        const beforeUrl = pathname + search;

        window.location.href = `${window.location.origin}${
          PATH.LOGIN
        }?before_url=${decodeURIComponent(beforeUrl)}`;

        clearTokens();
        processQueue(null, null);

        // return Promise.reject(refreshError);
        throw refreshError;
      }
    }

    return Promise.reject(error);
  },
);

export const get = async (uri: string, config?: AxiosRequestConfig) =>
  await httpClient.get(uri, config);

export const post = async (uri: string, data = {}, config?: any) =>
  await httpClient.post(uri, data as any, config);

export const put = async (uri: string, data = {}) => await httpClient.put(uri, data);
