export const PATH = {
  HOME: '/',
  FRIENDLY: '/friendly', // 친한구매
  SHOWCASE: '/showcase',
  SEARCH: '/search',

  PRODUCT_DETAIL: '/closure', // TODO: 폐쇄몰 이후 변경

  REGISTER: '/register',
  LOGIN: '/login',
  PASS_SUCCESS: '/pass/success',
  RESET_PASSWORD: '/login/reset-password',
  CART: '/cart',
  ORDER: '/order',
  ORDER_SUCCESS: '/order/success',
  ORDER_FAILED: '/order/failed',

  MYPAGE: '/mypage',
  MY_INFO: '/mypage/info',
  MY_WITHDRAWAL: '/mypage/withdrawal',
  MY_POINT: '/mypage/point',
  MY_COUPON: '/mypage/coupon',
  MY_INQUIRY: '/mypage/inquiry',
  MY_REVIEW: '/mypage/review',

  ORDER_LIST: '/mypage/orders',
  ORDER_REFUND: '/mypage/order/refund',
  ORDER_EXCHANGE: '/mypage/order/exchange',

  //TODO: 상위 경로의 페이지가 없는경우 첫번째 페이지로 진입하게 강제 경로 지정해줍니다.
  //ex) /help 접근 => /help/faq 로 페이지전환
  HELP: '/help',
  HELP_FAQ: '/help/faq',
  HELP_INQUIRY_CREATE: '/help/inquiry/create',
  HELP_NOTICE: '/help/notice',

  AGREEMENT_TERMS: '/agreement/terms',
  AGREEMENT_PRIVACY: '/agreement/privacy',

  EVENT: '/event',

  CALLBACK: '/callback',
  CALLBACK_PASS: '/callback/pass',
  CALLBACK_APPLE: '/callback/apple',
  CALLBACK_KAKAO: '/callback/kakao',
  CALLBACK_INSTAGRAM: '/callback/instagram',
};

export const ROUTES = [
  { path: PATH.HOME, title: '홈' },
  { path: PATH.REGISTER, title: '회원가입' }, //REGISTER
  { path: PATH.LOGIN, title: '로그인' }, //LOGIN
  { path: PATH.PASS_SUCCESS, title: '패스인증 성공' }, //PASS 인증 후 리다이렉트
  { path: PATH.CART, title: '장바구니' }, //CART
  { path: PATH.ORDER, title: '주문/결제' }, //ORDER
  { path: PATH.ORDER_SUCCESS, title: '주문/결제' }, //RESULT
  { path: PATH.MYPAGE, title: '마이' }, //MYPAGE
  { path: PATH.MY_INFO, title: '나의 정보 수정' }, //MY_INFO
  { path: PATH.MY_POINT, title: '나의 포인트' }, //MY_POINT
  { path: PATH.MY_COUPON, title: '나의 쿠폰' }, //MY_COUPON
  { path: PATH.MY_INQUIRY, title: '나의 문의' }, //MY_INQUIRY
  { path: PATH.MY_REVIEW, title: '나의 리뷰' }, //MY_REVIEW
  { path: PATH.ORDER_LIST, title: '주문/배송 내역' }, //ORDER_LIST
  { path: `${PATH.ORDER_LIST}/`, title: '주문 상세' }, //ORDER_LIST
  { path: PATH.ORDER_REFUND, title: '주문 상세' }, //ORDER_REFUND
  { path: PATH.HELP_FAQ, title: '자주하는 질문' }, //HELP_FAQ
  { path: PATH.HELP_INQUIRY_CREATE, title: '1:1 문의' }, //HELP_INQUIRY_CREATE
  { path: PATH.HELP_NOTICE, title: '공지사항' }, //HELP_NOTICE
  { path: PATH.AGREEMENT_TERMS, title: '이용약관' }, //AGREEMENT_TERMS
  { path: PATH.AGREEMENT_PRIVACY, title: '개인정보처리방침' }, //AGREEMENT_PRIVACY
  { path: PATH.EVENT, title: '' }, //EVENT
];

export const MENU_LIST = [
  {
    title: '구매 정보',
    list: [
      { title: '장바구니', path: PATH.CART },
      { title: '주문/배송 내역', path: PATH.ORDER_LIST },
    ],
  },
  // {
  //   title: '혜택 정보',
  //   list: [
  //     { title: '나의 포인트', path: PATH.MY_POINT },
  //     { title: '나의 쿠폰', path: PATH.MY_COUPON },
  //     { title: '친구 초대', path: PATH.EVENT },
  //   ],
  // },
  {
    title: '정보 관리',
    list: [
      { title: '나의 문의', path: PATH.MY_INQUIRY },
      { title: '나의 리뷰', path: PATH.MY_REVIEW },
      { title: '나의 정보수정', path: PATH.MY_INFO },
      { title: '배송지 관리', path: '#' },
    ],
  },
];

export const PRIVACY_AGREEMENT_URL =
  'https://pharma-bros.notion.site/82d10ca82ce44f52a0ce3b48e8307aee';
export const AGREEMENT_TERMS_URL =
  'https://pharma-bros.notion.site/a2710cf5796c434cbd339a00271006f7';
