import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { WoBoardArticleGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  qnaBoardTypeCd: string;
  qnaIdx: number;
  options?: QueryOptionsType<WoBoardArticleGetRes>;
}

export function useFetchInquiryThread({ qnaBoardTypeCd, qnaIdx, options }: Props) {
  const getInquiryThread = async () => {
    return await get(`/api/commerce/v1/boards/${qnaBoardTypeCd}/articles/${qnaIdx}/thread`).then(
      res => res.data as WoBoardArticleGetRes,
    );
  };

  return useQuery({
    queryKey: ['useFetchInquiryThread', { qnaBoardTypeCd, qnaIdx }],
    queryFn: getInquiryThread,
    ...options,
  });
}
