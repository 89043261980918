import { Fragment, useEffect, useState } from 'react';
import { CalendarModal } from '@components/common/molecules/modal/calendar-modal';
import { OrderListDesktop } from '@components/domain/mypage/order-list/order-list-desktop';
import { OrderListMobile } from '@components/domain/mypage/order-list/order-list-mobile';

import refreshIc from '@/assets/ic/refresh.svg';
import useMixpanel from '@/hooks/use-mixpanel';
import useOrderList from '@/hooks/use-order-list';
import { useSearchUrl } from '@/hooks/use-search-url';
import { dateToStringFormat } from '@/utils/formats';

export const MypageOrdersListTemplate = () => {
  const { setStateBySearchParams } = useSearchUrl();
  const { filterInitialData, offset, beginDate, endDate, handleClickPeriodBtn, periodGap } =
    useOrderList();
  const isMobile = window.innerWidth < 905;
  const limit = 10;
  const [isCalendarOpen, setIsCalendarOpen] = useState({ open: false, clicked: '' });
  const { handleMixpanelEvent } = useMixpanel();
  const toggleCalendar = (clicked?: string) => {
    setIsCalendarOpen(pre => ({ open: !pre.open, clicked: clicked ?? '' }));
  };

  const handleDatePicker = (type: string, date: Date) => {
    const currentBeginDate = new Date(beginDate);
    const currentEndDate = new Date(endDate);
    currentBeginDate.setHours(0, 0, 0, 0);
    currentEndDate.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    if ((type === 'start' && date > currentEndDate) || (type === 'end' && date < currentBeginDate))
      return;
    type === 'start'
      ? setStateBySearchParams([{ key: 'beginDate', value: dateToStringFormat(date, 'dash') }])
      : setStateBySearchParams([{ key: 'endDate', value: dateToStringFormat(date, 'dash') }]);
  };
  useEffect(() => {
    handleMixpanelEvent('view_my_default', {});
  }, []);

  const resetFilter = () => {
    handleClickPeriodBtn('6개월');
  };

  return (
    <main className="my-main">
      {/* <!-- 데스크탑에서만 나오는 section-title --> */}
      <section className="desktop-jumbotron section">
        <div className="section-title">
          <div className="label">주문/배송 내역</div>
        </div>
      </section>
      {/* <!-- 캘린더 탭 --> */}
      <ul className="section-contents tabs-calendar">
        {/* <!-- 기간 버튼 설정 --> */}
        <div className="btn-check-group">
          {['1개월', '6개월', '1년'].map((period, index, arr) => {
            return (
              <Fragment key={period}>
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id={`btnradio${index + 1}`}
                  readOnly
                  checked={periodGap === period}
                  onClick={() => handleClickPeriodBtn(period)}
                />
                <label
                  className={`btn btn-md btn-outline${
                    index === arr.length - 1 ? ' desktop-last-child' : ''
                  }`}
                  htmlFor={`btnradio${index + 1}`}
                >
                  {period}
                </label>
              </Fragment>
            );
          })}
          {/* <!-- 데스크탑에서 사라지는 버튼영역 --> */}
          <input
            type="radio"
            className="btn-check desktop-disabled"
            name="btnradio"
            id="btnradio4"
            readOnly
            checked={periodGap === '기간설정'}
          />
          <label className="btn btn-md btn-outline desktop-disabled" htmlFor="btnradio4">
            기간설정
          </label>
        </div>
        {/* <!-- 기간 상세 설정 --> */}
        <form className="forms-calendar">
          <input
            type="text"
            value={beginDate}
            pattern="\d{2}.\d{2}.\d{2}"
            readOnly
            onClick={() => toggleCalendar('start')}
          />
          <span>-</span>
          <input
            type="text"
            pattern="\d{2}.\d{2}.\d{2}"
            value={endDate}
            // value={`${endDate.getFullYear}/${endDate.getMonth() + 1}/${endDate.getDate()}`}
            readOnly
            onClick={() => toggleCalendar('end')}
          />
        </form>
        {isCalendarOpen.open && (
          <CalendarModal
            isOpen={isCalendarOpen.open}
            toggleCalendar={toggleCalendar}
            type={isCalendarOpen.clicked}
            selectedDate={
              isCalendarOpen.clicked === 'start' ? new Date(beginDate) : new Date(endDate)
            }
            handleDatePicker={handleDatePicker}
          />
        )}
        {/* <!-- 모바일 내역보기, 공통 새로고침--> */}
        <div className="btn-inline-group">
          <button type="button" className="btn btn-md btn-filled btn-block">
            내역보기
          </button>
          <button
            type="button"
            className="btn btn-md btn-outline btn-md-icon-only"
            onClick={resetFilter}
          >
            <img src={refreshIc} alt="refresh" />
          </button>
        </div>
      </ul>
      <div className="section-contents">
        {isMobile ? (
          <OrderListMobile limit={limit} offset={Number(offset)} />
        ) : (
          <OrderListDesktop limit={limit} offset={Number(offset)} />
        )}
      </div>

      {/* <!-- 제품 추천 --> */}
      <section className="mobile-mg-r-0">
        <div id="load-list-header-normal"></div>
        <div id="load-horizontal-prd"></div>
      </section>
      {/* <!-- Success 일 때 divider + 제품 추천영역 보여주기 --> */}
      <div style={{ display: 'none' }} className="mobile-divider" />
      {/* <!-- 배송지 확인 얼럿 --> */}
      <div style={{ display: 'none' }} id="load-payment-alert" />
    </main>
  );
};
