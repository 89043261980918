import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useFetchGetUserDetail } from '@/apis/commerce/self/useFetchGetUserDetail';
import { useMutationConnectCert } from '@/apis/friendly-pharmacist/auth/useMutationConnectCert';
import { useFetchGetUserInfo } from '@/apis/friendly-pharmacist/user/useFetchGetUserInfo';
import { useFetchPassUrl } from '@/apis/friendly-pharmacist/user/useFetchPassUrl';
import { useMutationLoginEmail } from '@/apis/friendly-pharmacist/user/useMutationLoginEmail';
import { useMutationPassValidation } from '@/apis/friendly-pharmacist/user/useMutationPassValidation';
import { useMutationPassValidationUpdate } from '@/apis/friendly-pharmacist/user/useMutationPassValidationUpdate';
import {
  RegisterEmailParams,
  useMutationRegisterEmail,
} from '@/apis/friendly-pharmacist/user/useMutationRegisterEmail';
import { useMutationRegisterSocial } from '@/apis/friendly-pharmacist/user/useMutationRegisterSocial';
import { getUserInfo, userLoginSocial } from '@/apis/friendly-pharmacist/user/user';
import {
  AUTH_APPLE_CLIENT_ID,
  AUTH_KAKAO_ACCESS_TOKEN,
  AUTH_PASS_KEY,
  AUTH_REDIRECT_URL_KEY,
  AUTH_REFRESH_TOKEN_KEY,
  AUTH_TOKEN_KEY,
} from '@/constants/auth';
import { PATH } from '@/constants/path';
import { ADS, ADS_TOTAL, TERMS_LIST } from '@/constants/terms';
import useMixpanel from '@/hooks/use-mixpanel';
import { useSearchUrl } from '@/hooks/use-search-url';
import {
  getSessionStorage,
  removeLocalStorage,
  removeSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from '@/utils';

import useLoginInfo from './use-login-info';
import { useMutationRegisterKakao } from '@/apis/friendly-pharmacist/user/useMutationRegisterKakao';
import { validation } from '@/utils/validations';

const Kakao = window.Kakao;

/**
 * kakao
    * login -> ci check -> redirect
    * login -> no ci -> PASS update -> redirect
    * login -> not registered -> terms -> register -> redirect

 * email
    * login -> ci checked -> redirect
    * login -> no ci -> PASS update -> redirect
    *
    *
  *
 */
const ERROR_MESSAGE = {
  UNAUTHORIZED: 'PASS 인증이 만료되었습니다.',
  ALREADY_EXIST_EMAIL: '이미 가입된 이메일입니다.',
  INTERNAL_SERVER_ERROR: '서버 오류가 발생했습니다.',
};
type ErrorMessageKey = keyof typeof ERROR_MESSAGE;
export default function useAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userToken } = useLoginInfo();
  const [keepLoginState, setKeepLoginState] = useState(true);
  const [loginValue, setLoginValue] = useState({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const { handleMixpanelEvent } = useMixpanel();
  const [alertState, setAlertState] = useState<{
    isShow: boolean;
    message: string[];
    buttons: [{ title: string; handleClick: Function }];
  }>({ isShow: false, message: [''], buttons: [{ title: '', handleClick: () => {} }] });
  const recentViewedPrds = getSessionStorage('rct_p') || null;
  const appleInfo = getSessionStorage('apple');
  const appleId = appleInfo?.token ? jwtDecode(appleInfo.token).sub : '';
  const [selectedItems, setSelectedItems] = useState({ sms: false, email: false, push: false });
  const { getStateBySearchParams, setStateBySearchParams, deleteBySearchParams } = useSearchUrl();
  const mdlTkn = getStateBySearchParams('mdl_tkn') || '';
  const agreedList = getStateBySearchParams('agreed')?.split(',') ?? [];
  const registerType = getStateBySearchParams('registerType') ?? '';
  const setRegisterType = (type: string) => {
    setStateBySearchParams([{ key: 'registerType', value: type }], location.state);
  };
  const deleteRegisterType = () => deleteBySearchParams('registerType');
  const beforeUrl = getStateBySearchParams('before_url') ?? '';
  // 친한약사 api
  const { data: passUrlData } = useFetchPassUrl({
    redirectUrl: `${window.location.origin}${PATH.CALLBACK_PASS}`,
  });
  const { mutate: mutateConnectCert } = useMutationConnectCert();
  const { mutate: mutatePassValidation } = useMutationPassValidation();
  const { mutate: mutatePassValidationUpdate } = useMutationPassValidationUpdate();
  const { mutate: mutateLoginEmail } = useMutationLoginEmail();
  const { mutate: mutateRegisterSocial } = useMutationRegisterSocial({});
  const { mutate: mutateRegisterKakao } = useMutationRegisterKakao();
  const { mutate: mutateRegister } = useMutationRegisterEmail();
  const { data: userInfoData } = useFetchGetUserInfo({
    accessToken: userToken,
    options: { enabled: !!userToken },
  });

  // 스토어 api
  const {
    data: userDetail,
    refetch: refetchUserDetail,
    error: userDetailError,
  } = useFetchGetUserDetail({
    accessToken: userToken,
  });

  const initKakao = () => {
    if (!(Kakao?.isInitialized() as boolean)) {
      Kakao.init(`${process.env.REACT_APP_KAKAO_KEY as string}`);
    }
  };

  useEffect(() => {
    initKakao();
  }, []);

  const removeAuthInfo = () => {
    removeSessionStorage(AUTH_KAKAO_ACCESS_TOKEN);
    removeSessionStorage(AUTH_REDIRECT_URL_KEY);
    removeSessionStorage('kakao');
    removeSessionStorage('apple');
    deleteBySearchParams('registerType');
    deleteBySearchParams('agreed');
  };
  const removeAccessToken = () => {
    removeSessionStorage(AUTH_TOKEN_KEY);
    removeSessionStorage(AUTH_REFRESH_TOKEN_KEY);
    removeLocalStorage(AUTH_TOKEN_KEY);
    removeLocalStorage(AUTH_REFRESH_TOKEN_KEY);
  };

  /************************************
   * Login
   ************************************/
  const handleKeepLoginState = (e: ChangeEvent<HTMLInputElement>) => {
    setKeepLoginState(e.target.checked);
  };

  /**
   * 콜백페이지에서 인증완료후 전환될 페이지
   */
  const setAuthRedirectUrl = () => {
    sessionStorage.setItem(AUTH_REDIRECT_URL_KEY, `${location.pathname}${location.search || ''}`);
  };

  const kakaoLogin = () => {
    setAuthRedirectUrl();
    Kakao.Auth.authorize({
      redirectUri: `${window.location.origin}${PATH.CALLBACK_KAKAO}`,
      prompts: 'login',
    });
  };
  const APPLE_LOGIN_REDIRECT_URL = `${window.location.origin}${PATH.CALLBACK_APPLE}`;

  const appleLogin = async () => {
    const config = {
      client_id: AUTH_APPLE_CLIENT_ID, // This is the service ID we created.
      redirect_uri: APPLE_LOGIN_REDIRECT_URL, // As registered along with our service ID
      response_type: 'code id_token',
      state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
      scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
      response_mode: 'fragment', // fragment || form_post
      usePopup: false,
    };
    setAuthRedirectUrl();
    window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${config.client_id}&redirect_uri=${config.redirect_uri}&response_type=${config.response_type}&state=${config.state}&scope=&response_mode=${config.response_mode}`;
  };

  const onSuccessKakaoLoginToApiRequest = (accessToken?: string) => {
    //카카오 계정 정보를 가져오기위해 카카오 억세스토큰을 카카오 sdk에 주입해줍니다.
    if (accessToken) {
      Kakao.Auth.setAccessToken(accessToken);
    }
    Kakao.API.request({
      url: '/v2/user/me',
      success: async (res: any) => {
        if (res.id !== undefined && res.id !== null) {
          await handleLoginWithSocial({ type: 'kakao', id: String(res.id) });
          setSessionStorage('kakao', { id: res.id, email: res.kakao_account.email });
        }
      },
      fail: (e: any) => {
        toast.error('카카오 연동에 실패했어요. 다시 시도해주세요.');
        removeSessionStorage('kakao');
      },
    });
  };

  const handleRegisterWithKakao = () => {
    // 카카오에서 ci값을 받아오기 때문에 PASS인증 불필요하여 api 분리 됨
    setIsLoading(true);
    mutateRegisterKakao(
      {
        kakao_access_token: sessionStorage.getItem(AUTH_KAKAO_ACCESS_TOKEN) ?? '',
        agreements: {
          sms: agreedList.includes('4'),
          email: agreedList.includes('5'),
          push: agreedList.includes('6'),
        },
      },
      {
        onSuccess: async () => {
          handleMixpanelEvent('complete_signup_store', { type: 'kakao' });
          toast.success('카카오 회원가입이 완료됐어요!');
          const kakaoInfo = getSessionStorage('kakao');

          // sessionStorage.removeItem(AUTH_KAKAO_ACCESS_TOKEN);
          kakaoInfo?.id
            ? await onSuccessKakaoLogin({ id: kakaoInfo?.id })
            : toast.success('카카오 가입이 완료됐어요!\n다시 로그인 해 주세요.');
          setIsLoading(false);
        },
        onError: (err: any) => {
          if (err.response?.status === 409) {
            const existingAccountInfo: {
              email: string;
              phone: string;
              type: string;
            } = JSON.parse(err.response?.data?.message);

            deleteBySearchParams('agreed');
            setStateBySearchParams([
              { key: 'registerType', value: 'exist' },
              { key: 'email', value: encodeURIComponent(existingAccountInfo?.email ?? '') },
              { key: 'sns_type', value: existingAccountInfo?.type ?? '' },
            ]);
            // 카카오로 회원가입 실패시
          } else {
            toast.error('카카오 연동이 실패하였습니다. 다시 시도해주세요.');
          }
          removeSessionStorage('kakao');
          removeSessionStorage(AUTH_KAKAO_ACCESS_TOKEN);
          setIsLoading(false);
        },
      },
    );
  };

  const handleLoginWithSocial = async ({ id, type }: { id: string; type: 'apple' | 'kakao' }) => {
    try {
      const res = await userLoginSocial({
        sns_id: id,
      });

      await handleLoginSuccess(res?.data?.access_token, res?.data?.refresh_token);
    } catch (e: any) {
      // 소셜 로그인 실패시 가입 시도
      setRegisterType(type === 'apple' ? 'apple' : 'kakao');
      if (e?.response?.status === 401) {
        type === 'apple' ? handleOpenTermsModal() : handleRegisterWithKakao();
      }
      setIsLoading(false);
    }
  };

  const onSuccessKakaoLogin = async ({ id }: { id: string }) => {
    setIsLoading(true);

    try {
      const res = await userLoginSocial({
        sns_id: id,
      });

      await handleLoginSuccess(res?.data?.access_token, res?.data?.refresh_token);
    } catch (e: any) {
      // 카카오 로그인 실패시 가입 시도
      setRegisterType('kakao');
      e?.response?.status === 401 && handleRegisterWithKakao();
      setIsLoading(false);
    }
  };

  const handleLoginWithKakao = () => {
    kakaoLogin();
    handleMixpanelEvent('click_login_store', { type: 'kakao' });
  };

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setLoginValue({ ...loginValue, [name]: value });
  };

  const handleLoginWithEmail = (email: string, password: string) => {
    setIsLoading(true);
    handleMixpanelEvent('click_login_store', { type: 'email' });

    if (password.length < 8 || !validation('email', email)) {
      toast.error('유효하지 않은 로그인 정보입니다.');
      setIsLoading(false);
      return;
    }
    mutateLoginEmail(
      { email, password },
      {
        onSuccess: async ({ data }) => {
          if (data?.access_token) {
            await handleLoginSuccess(data.access_token, data.refresh_token);
          } else {
            setIsLoading(false);
            toast.error('로그인 정보를 다시 확인해주세요!');
          }
        },
        onError: (err: any) => {
          setIsLoading(false);
          toast.error(err?.response?.data?.message ?? '로그인 정보를 다시 확인해주세요!');
        },
      },
    );
  };

  const loginSuccessToMovePage = () => {
    removeAuthInfo();

    const authUrl = [PATH.LOGIN, PATH.REGISTER].find(path => beforeUrl?.includes(path));
    navigate(!!authUrl || !beforeUrl ? PATH.MYPAGE : beforeUrl, { replace: true });
  };

  const handleLoginSuccess = async (access_token?: string, refresh_token?: string) => {
    setIsLoading(true);
    if (access_token && refresh_token) {
      if (keepLoginState) {
        localStorage.setItem(AUTH_TOKEN_KEY, access_token);
        localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, refresh_token);
      } else {
        sessionStorage.setItem(AUTH_TOKEN_KEY, access_token);
        sessionStorage.setItem(AUTH_REFRESH_TOKEN_KEY, refresh_token);
      }
    }

    keepLoginState && setLocalStorage('keep_tkn', true);

    try {
      /**
       * CI 판단을 위해 인증받은 토큰으로 가입유무를 판단합니다.
       * react-query 갱신 시간이 있으므로 별도 요청해 응답값을 판별합니다.
       */
      const { data } = await getUserInfo({ accessToken: access_token });
      await refetchUserDetail().then(({ data: userDetailData }) => {
        if (
          !!data?.connect_accounts?.length ||
          (data?.user_info?.account_sns_type === 'kakao' && !!userDetailData?.self_detail?.phone)
        ) {
          loginSuccessToMovePage();
        } else {
          setRegisterType('pass_update');
          handleOpenTermsModal();
        }
      });

      if (
        !!data?.connect_accounts?.length ||
        (data?.user_info?.account_sns_type === 'kakao' && !!data?.user_info?.phone)
      ) {
        loginSuccessToMovePage();
      } else {
        setRegisterType('pass_update');
        handleOpenTermsModal();
      }

      setIsLoading(false);
    } catch (e: any) {
      if (e.response.status === 409) {
        handleOpenTermsModal();
        setRegisterType('pass_update');
      }
      setIsLoading(false);
    }
  };

  /*******************************************
   * useTerms
   ******************************************/

  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const removeEmptyStrings = (arr: string[]) => arr.filter(item => item.trim() !== '');
    const updateSearchParams = (newList: string[]) => {
      setStateBySearchParams(
        [
          {
            key: 'agreed',
            value: removeEmptyStrings(newList).join(','),
          },
        ],
        location.state,
      );
    };

    switch (value) {
      case String(ADS_TOTAL):
        agreedList.includes('3')
          ? setStateBySearchParams(
              [
                {
                  key: 'agreed',
                  value: agreedList.filter(id => !ADS.includes(Number(id))).join(','),
                },
              ],
              location.state,
            )
          : setStateBySearchParams(
              [{ key: 'agreed', value: [...agreedList, ...ADS].join(',') }],
              location.state,
            );
        break;

      case '4':
      case '5':
      case '6': {
        let newArray = agreedList.includes(value)
          ? agreedList.filter(id => id !== value)
          : [...agreedList, value];

        const hasIndividualAds = ADS.slice(1).some(adId => newArray.includes(String(adId)));
        const hasTotalAds = newArray.includes(String(ADS_TOTAL));

        if (hasIndividualAds && !hasTotalAds) {
          newArray.push(String(ADS_TOTAL));
        } else if (!hasIndividualAds && hasTotalAds) {
          newArray = newArray.filter(id => id !== String(ADS_TOTAL));
        }

        updateSearchParams(newArray);
        break;
      }

      default:
        updateSearchParams(
          agreedList.includes(value)
            ? agreedList.filter(id => id !== value)
            : [...agreedList, value],
        );
    }
  };

  const handleCheckAll = () => {
    agreedList.length > 5
      ? deleteBySearchParams('agreed')
      : setStateBySearchParams(
          [{ key: 'agreed', value: TERMS_LIST.map(item => item.id).join(',') }],
          location.state,
        );
  };

  const registerTypeInit = () => {
    deleteBySearchParams('registerType');
  };

  const handleOpenTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const handleCloseTermsModal = () => {
    if (isTermsModalOpen) {
      removeAuthInfo();
      removeAccessToken();
      setIsTermsModalOpen(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertState(pre => ({ ...pre, isShow: !pre.isShow }));
  };

  /**
   * 회원가입 시 인증하기 버튼 클릭 (PASS 실행)
   * 리다이렉트 방식
   */
  const handlePASSCertification = () => {
    if (registerType === 'kakao') {
      handleRegisterWithKakao();
      return;
    }
    const passUrl = passUrlData?.data?.pass_auth_url;
    setAuthRedirectUrl();
    window.location.href = passUrl;
  };

  /**
   * 패스인증 토큰으로 계정 유효성 검사를 합니다.
   * 패스인증 성공 => mdl_tkn 전달 => 패스 인증 유무 API 호출 => CI 판단
   * @param mdl_tkn
   */
  const connectCert = (mdl_tkn: string) => {
    setIsLoading(true);
    sessionStorage.removeItem(AUTH_PASS_KEY);

    const selectedValue = TERMS_LIST.slice(3)
      .filter(item => agreedList.includes(`${item.id}`))
      .reduce((acc, curr) => {
        return { ...acc, [curr.name]: true };
      }, {});

    mutateConnectCert(
      { tkn: mdl_tkn },
      {
        onSuccess: () => {
          setSelectedItems(pre => ({ ...pre, ...selectedValue }));
          // 가입한 계정이 있고 CI 연동이 안되어있을 경우
          if (!!userToken && !userInfoData?.connect_accounts?.length) {
            passValidationUpdate(mdl_tkn);
          } else {
            passValidation(mdl_tkn);
          }
        },
        onError: (err: any) => {
          toast.error('패스인증에 실패했습니다. 다시 시도해주세요.');
          setIsLoading(false);
        },
      },
    );
  };

  /**
   * 가입계정은 있지만 패스인증이 없는 계정일 경우
   * 패스인증 상태를 업데이트 합니다.
   * @param mdl_tkn
   */
  const passValidationUpdate = (mdl_tkn: string) => {
    mutatePassValidationUpdate(
      { tkn: mdl_tkn },
      {
        onSuccess: async () => {
          await handleLoginSuccess(
            userToken,
            localStorage.getItem(AUTH_REFRESH_TOKEN_KEY) ??
              sessionStorage.getItem(AUTH_REFRESH_TOKEN_KEY) ??
              '',
          );
          handleCloseTermsModal();
        },
        onError: (err: any) => {
          handleCloseTermsModal();
          setIsLoading(false);
          if (err?.response?.status === 401) {
            toast.error('이미 PASS 인증이 완료된 회원입니다. 고객센터로 문의해주세요!');
          }
          removeAuthInfo();
          removeAccessToken();
          return;
        },
      },
    );
  };

  /**
   * 패스인증 유저 유효성 검사
   * @param tkn
   */
  const passValidation = (mdl_tkn: string) => {
    mutatePassValidation(mdl_tkn, {
      // 성공시 /register
      onSuccess: ({ data }) => {
        //계정값이 없을 경우 가입
        if (data?.is_valid && !data?.existing_accounts?.length) {
          if (registerType === 'apple') {
            registerSocial(mdl_tkn);
          } else {
            setStateBySearchParams([{ key: 'mdl_tkn', value: mdl_tkn }]);
          }
          setIsLoading(false);
          return;
        }

        //이미 가입된 계정이 있을 경우
        if (data?.existing_accounts.length > 0) {
          removeAuthInfo();
          setStateBySearchParams([
            { key: 'registerType', value: 'exist' },
            { key: 'email', value: encodeURIComponent(data.existing_accounts[0]?.email ?? '') },
            { key: 'sns_type', value: data.existing_accounts[0]?.sns_type ?? '' },
          ]);

          deleteBySearchParams('agreed');
          handleCloseTermsModal();
          setIsLoading(false);
        }

        if (data.errorCode !== undefined) {
          alert(data.message);
          setIsLoading(false);
        }
      },
      onError: (err: any) => {
        toast.error('PASS 인증에 실패했어요. 다시 시도해주세요.');
        setIsLoading(false);
      },
    });
  };

  const registerSocial = (mdl_tkn: string) => {
    const agreements = {
      sms: agreedList.includes('4'),
      email: agreedList.includes('5'),
      push: agreedList.includes('6'),
    };

    switch (registerType) {
      case 'kakao':
        handleRegisterWithKakao();
        return;
      case 'apple': {
        mutateRegisterSocial(
          {
            provider: registerType,
            passToken: mdl_tkn,
            appleIdToken: appleInfo.token,
            agreements,
          },
          {
            onSuccess: async () => {
              handleMixpanelEvent('complete_signup_store', { type: 'apple' });
              appleId && (await handleLoginWithSocial({ type: 'apple', id: appleId }));
              setIsLoading(false);
            },
            onError: (err: any) => {
              const { errorCode, message } = err?.response?.data;
              if (Object.keys(ERROR_MESSAGE).includes(errorCode)) {
                toast.error(message);
              }

              removeSessionStorage('apple');
              removeSessionStorage('kakao');
              removeSessionStorage(AUTH_KAKAO_ACCESS_TOKEN);

              if (err.response?.status === 409) {
                const existingAccountInfo =
                  typeof err.response?.data?.message === 'string'
                    ? JSON.parse(err.response?.data?.message)
                    : err.response?.data?.message;
                deleteBySearchParams('agreed');

                if (existingAccountInfo?.email && existingAccountInfo?.type) {
                  setStateBySearchParams([
                    { key: 'registerType', value: 'exist' },
                    { key: 'email', value: encodeURIComponent(existingAccountInfo?.email ?? '') },
                    { key: 'sns_type', value: existingAccountInfo?.type ?? '' },
                  ]);
                } else {
                  toast.error(message);
                  deleteBySearchParams('registerType');
                }
              } else {
                toast.error('회원가입에 실패했어요. 다시 시도해주세요.');
              }

              setIsLoading(false);
            },
          },
        );
      }
    }
  };

  const handleRegisterWithEmail = (userValue: RegisterEmailParams) => {
    setIsLoading(true);

    mutateRegister(userValue, {
      onSuccess: async ({ data }) => {
        data?.access_token && sessionStorage.setItem(AUTH_TOKEN_KEY, data.access_token);
        data?.refresh_token && sessionStorage.setItem(AUTH_REFRESH_TOKEN_KEY, data.refresh_token);
        handleMixpanelEvent('complete_signup_store', { type: 'email' });
        loginSuccessToMovePage();
        setIsLoading(false);
      },
      onError: (err: any) => {
        const { errorCode, message } = err?.response?.data;
        if (Object.keys(ERROR_MESSAGE).includes(errorCode)) {
          toast.error(message);
        }
        const existingAccountInfo =
          typeof err.response?.data?.message === 'string'
            ? JSON.parse(err.response?.data?.message)
            : err.response?.data?.message;

        if (err.response?.status === 409) {
          deleteBySearchParams('agreed');

          setStateBySearchParams([
            { key: 'registerType', value: 'exist' },
            { key: 'email', value: encodeURIComponent(existingAccountInfo?.email ?? '') },
            { key: 'sns_type', value: existingAccountInfo?.type ?? '' },
          ]);
        } else {
          toast.error('회원가입에 실패했어요. 다시 시도해주세요.');
        }
        setIsLoading(false);
      },
    });
  };

  return {
    isLoading,
    appleId,
    mdlTkn,
    keepLoginState,
    agreedList,
    registerType,
    isTermsModalOpen,
    loginValue,
    selectedItems,
    alertState,
    handleRegisterWithEmail,
    handleLoginWithSocial,
    registerTypeInit,
    handleKeepLoginState,
    deleteRegisterType,
    setRegisterType,
    handleCloseAlert,
    setSelectedItems,
    refetchUserDetail,
    handleLoginWithKakao,
    handleLoginWithEmail,
    handleChangeValue,
    kakaoLogin,
    initKakao,
    onSuccessKakaoLoginToApiRequest,
    handleChangeCheckbox,
    handleCheckAll,
    handlePASSCertification,
    connectCert,
    handleOpenTermsModal,
    handleCloseTermsModal,
    appleLogin,
  };
}
