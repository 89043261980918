import { Fragment, useEffect } from 'react';

interface ToastProps {
  message: string;
  subMsgs?: string[];
  toastType?: string; // warning, checked, marketing
  duration?: number;
  handleClickClose?: () => void;
}

export const Toast = ({
  message,
  duration = 1000,
  handleClickClose,
  toastType,
  subMsgs,
}: ToastProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      handleClickClose && handleClickClose();
    }, duration);
    return () => clearTimeout(timer);
  }, [handleClickClose]);

  return (
    <span className={`toast ${toastType ?? ''}`}>
      {subMsgs ? (
        <>
          <span>{message}</span>
          <span className="sub-text">
            {subMsgs.map((msg, idx) => (
              <Fragment key={msg}>
                {idx > 0 && <br />}
                {msg}
              </Fragment>
            ))}
          </span>
        </>
      ) : (
        <span>{message}</span>
      )}
    </span>
  );
};
