import { Location } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { get } from '../../api';

export function useFetchServiceInspectionStatus(location: Location<any>) {
  const getStatus = async () => {
    const res = await get('/api/commerce/v1/settings/is-under-maintenance');
    return res?.data;
  };

  return useQuery({
    queryKey: ['useFetchServiceInspectionStatus', location],
    queryFn: getStatus,
    staleTime: 1000 * 60,
  });
}
