import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReportModal } from '@components/common/molecules/modal/report-modal';

import { useFetchProductReview } from '@/apis/commerce/products/useFetchProductReview';
import { useFetchReviewWritable } from '@/apis/commerce/products/useFetchReviewWritable';
import starIc from '@/assets/ic/star.svg';
import emptyStarIc from '@/assets/ic/star-gr.svg';
import { ReviewModal } from '@/components/domain/review/review-modal';
import { WoReviewStat } from '@/generated/api/type/data-contracts';
import useMixpanel from '@/hooks/use-mixpanel';
import { dateToStringFormat, numberFormat } from '@/utils/formats';

import { ReviewNotice } from './review-notice';
import useLoginInfo from '@/hooks/use-login-info';

/**
 * 상품 상세 리뷰 컴포넌트
 */
export const Review = () => {
  const { id } = useParams();
  const { isLogin } = useLoginInfo();
  const { handleMixpanelEvent } = useMixpanel();
  const [toggleModal, setToggleModal] = useState('');
  const [reportReviewIdx, setReportReviewIdx] = useState(0);
  const [selectedSort, setSelectedSort] = useState('created_at');
  const offset = 0;
  // const [offset, setOffset] = useState(0);
  // const isMobile = window.innerWidth < 905;
  const limit = 20;
  const { data: reviewWritable, refetch: refetchReviewWritable } = useFetchReviewWritable({
    productIdx: id ?? '0',
    options: { enabled: isLogin, staleTime: 0 },
  });
  const { data: reviewData, refetch: refetchReview } = useFetchProductReview({
    order: selectedSort,
    productIdx: id ?? '0',
    offset,
    limit,
    options: {
      staleTime: 0,
    },
  });

  const modalOpen = (type: string) => {
    setToggleModal(type);
  };

  const handleClickSort = (sort: string) => {
    handleMixpanelEvent('click_review_sorting', {
      type: sort === 'created_at' ? 'recent' : 'star',
      product_id: id,
    });
    setSelectedSort(sort);
  };

  const closeModal = (success?: boolean) => {
    setToggleModal('');
    if (success) {
      refetchReview();
      refetchReviewWritable();
    }
  };

  const scoreArray = Array.from({ length: 5 }, (_, i) => ({
    score: 5 - i,
    count: reviewData?.review_stat?.[`score${5 - i}_count` as keyof WoReviewStat] ?? 0,
  }));
  const reviewTotal = reviewData?.total_count ?? 0;
  const percentage = (reviews: number) => (reviews / reviewTotal) * 100;
  const satisfied = percentage(scoreArray?.[0]?.count + scoreArray?.[1]?.count);

  return (
    <>
      {toggleModal === 'review' && (
        <ReviewModal
          isOpen={true}
          toggleModal={closeModal}
          prdId={id ?? ''}
          createSuccess={refetchReview}
        />
      )}
      {toggleModal === 'report' && (
        <ReportModal isOpen={true} toggleModal={closeModal} reviewIdx={reportReviewIdx} />
      )}
      <div className="tabs-contents reviews" id="content3" style={{ display: 'block' }}>
        {reviewTotal <= 0 ||
        reviewData?.reviews === undefined ||
        reviewData?.reviews?.length <= 0 ? (
          <>
            <ReviewNotice
              reviewWritable={reviewWritable?.review_writable_yn === 'Y'}
              modalOpen={modalOpen}
            />
            <div className="empty-list">
              <div className="lines">
                <span>&quot;첫 리뷰를 남겨주세요&quot;</span>
                <span className="line"></span>
              </div>
              <div className="sub-text">
                아직 작성된 리뷰가 없습니다. <br /> 다른 고객님의 선택에 도움을 주세요.
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="product-list-filter">
              <div className="result-value">리뷰 {numberFormat(reviewTotal)}</div>
              {/* <!-- desktop xlg sorting --> */}
              <ul className="desktop-list-sorting">
                <li>
                  <button
                    type="button"
                    className={`btn btn-subtle btn-sorting${
                      selectedSort === 'review_rating_score' ? ' active' : ''
                    }`}
                    value="review_rating_score"
                    onClick={e => handleClickSort(e.currentTarget.value)}
                  >
                    별점순
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`btn btn-subtle btn-sorting${
                      selectedSort === 'created_at' ? ' active' : ''
                    }`}
                    value="created_at"
                    onClick={e => handleClickSort(e.currentTarget.value)}
                  >
                    최신순
                  </button>
                </li>
              </ul>
            </div>
            <ReviewNotice
              reviewWritable={reviewWritable?.review_writable_yn === 'Y'}
              modalOpen={modalOpen}
            />

            <div className="graphs">
              <div className="col">
                <div className="score">
                  {reviewData?.review_stat?.review_score_average?.toFixed(1)}
                </div>
                <div className="stars">
                  {new Array(5).fill(false).map((_, idx) => (
                    <img
                      src={
                        idx < (reviewData?.review_stat?.review_score_average ?? 0)
                          ? starIc
                          : emptyStarIc
                      }
                      alt="star"
                      key={`review_select_start${idx}`}
                    />
                  ))}
                </div>
                <div className="lines">
                  <span>“{satisfied.toFixed(1)}% 고객님들이 만족했어요!”</span>
                  <span className="line"></span>
                </div>
              </div>
              <div className="col">
                {scoreArray?.map((item, idx) => (
                  <div className="bar-group" key={`bar-group-${idx}${item?.score}`}>
                    <div className="state">
                      <div>{numberFormat(item?.count)}</div>
                      <div>({percentage(item?.count ?? 0).toFixed(1)}%)</div>
                    </div>
                    <div className="bar-gutter">
                      <span
                        style={{ height: `${percentage(item?.count ?? 0)}%` }}
                        className="bar"
                      />
                    </div>
                    <div className="label">{item?.score}점</div>
                  </div>
                ))}
              </div>
            </div>
            <ul className="review-list">
              {reviewData?.reviews?.map(review => (
                <li key={`review_${review?.review_idx}`}>
                  <div className="user-info">
                    <div className="info">
                      <div className="stars">
                        {new Array(5).fill(false).map((_, idx) => (
                          <img
                            src={idx < (review?.review_rating_score ?? 0) ? starIc : emptyStarIc}
                            alt="star"
                            key={`review_select_start${idx}`}
                          />
                        ))}
                        <span className="score">{review?.review_rating_score}점</span>
                      </div>
                      <div className="usertext">
                        <span>{review?.masked_nickname}</span>
                        <span>{dateToStringFormat(new Date(review?.created_at ?? ''), 'dot')}</span>
                      </div>
                    </div>
                  </div>
                  <div className="badge-group">
                    {(review?.sales_count ?? 0) > 0 && (
                      <span className="badge red">구매 {numberFormat(review?.sales_count)}회</span>
                    )}
                    {/* <span className="badge">무료배송</span>
                    <span className="badge">사은품</span> */}
                  </div>
                  <div className="title">
                    <span>[구매상품]</span>
                    <span>{review?.option_name}</span>
                  </div>
                  <div className="text">{review?.review_text_auto}</div>
                  {review?.image_url && (
                    <img className="review-img" src={review?.image_url} alt="" />
                  )}
                  <Link
                    className="report"
                    to="#"
                    onClick={() => {
                      modalOpen('report');
                      setReportReviewIdx(review?.review_idx ?? 0);
                    }}
                  >
                    신고
                  </Link>
                </li>
              ))}
            </ul>
            {/* // TODO: pagination */}
            <div id="load-pagenation"></div>
          </>
        )}
      </div>
    </>
  );
};
