import { Link } from 'react-router-dom';

import { PATH } from '@/constants/path';
import useMixpanel from '@/hooks/use-mixpanel';

export const Footer = () => {
  const isMobile = window.innerWidth < 905;
  const { handleMixpanelEvent } = useMixpanel();
  return (
    <footer>
      <div id="footer" className="footer">
        {isMobile && (
          <div className="col mobile-col">
            <ul className="link-group">
              <li className="link-item">
                <Link
                  onClick={() => handleMixpanelEvent('click_footer_link', { type: 'faq' })}
                  to={PATH.HELP_FAQ}
                >
                  자주하는 질문
                </Link>
              </li>
              <li className="link-item">
                <Link
                  onClick={() => handleMixpanelEvent('click_footer_link', { type: '1:1' })}
                  to={PATH.HELP_INQUIRY_CREATE}
                >
                  1:1 문의
                </Link>
              </li>
              <li className="link-item">
                <Link
                  onClick={() => handleMixpanelEvent('click_footer_link', { type: 'notice' })}
                  to={PATH.HELP_NOTICE}
                >
                  공지사항
                </Link>
              </li>
            </ul>
          </div>
        )}
        <div className="col">
          <ul className="link-group">
            <li className="link-item">
              <Link to="https://pharma-bros.com/" target="_blank">
                회사소개
              </Link>
            </li>
            <li className="link-item">
              <Link
                to="https://pharma-bros.notion.site/a2710cf5796c434cbd339a00271006f7"
                target="_blank"
              >
                이용약관
              </Link>
            </li>
            <li className="link-item bold">
              <Link
                to="https://pharma-bros.notion.site/82d10ca82ce44f52a0ce3b48e8307aee"
                target="_blank"
              >
                개인정보처리방침
              </Link>
            </li>
            <li className="link-item desktop-item">
              <Link to={PATH.HELP_FAQ}>고객센터</Link>
            </li>
          </ul>
          <ul className="info-group">
            <li className="info-item">(주) 파마브로스</li>
            <li className="info-item">대표이사 : 임별, 고상온</li>
            <li className="info-item">서울특별시 강남구 논현로94길 30, 4층</li>
            <li className="info-item">사업자등록번호 291-88-02499</li>
            <li className="info-item">통신판매업신고번호 제 2022-서울강남-06202 </li>
          </ul>
          <div className="copyright">ⓒPharma-bros corp. All rights reserved.</div>
        </div>
      </div>
    </footer>
  );
};
