import { useMutation } from '@tanstack/react-query';
import { post } from '@/apis/api';

export function useMutationLogout({
  onSuccess,
  onError,
}: {
  onSuccess: (res: { message: string }) => void;
  onError: (error: Error) => void;
}) {
  const logout = async ({ refreshToken }: { refreshToken: string }) => {
    return await post('/auth/tokens/logout', { refresh_token: refreshToken }).then(
      res => res?.data?.data,
    );
  };

  return useMutation({ mutationFn: logout, onSuccess, onError });
}
