import { Fragment } from 'react/jsx-runtime';

import brandIc from '@/assets/ic/brand-logo-wt.svg';
import errorIc from '@/assets/ic/error.png';
import { Link } from 'react-router-dom';

const ERROR_TEXT = [
  {
    type: 'inspection',
    title: ['현재 페이지 점검 중 입니다.'],
    content: [
      '빠른 시간 내에 점검을 완료하여 더 좋은 모습으로 찾아뵙겠습니다.',
      '서비스 이용에 불편을 드려 죄송합니다.',
    ],
  },
  {
    type: 'noProduct',
    title: ['판매가 종료되거나', '판매중인 상품이 없습니다.'],
    content: ['서비스 이용에 불편을 드려 죄송합니다.'],
  },
  {
    type: 'error',
    title: ['서비스에 접속할 수 없습니다.'],
    content: [
      '빠른 시간 내에 점검을 완료하여 더 좋은 모습으로 찾아뵙겠습니다.',
      '서비스 이용에 불편을 드려 죄송합니다.',
    ],
  },
];

interface ErrorTemplateProps {
  type?: string;
}
export const ErrorTemplate = ({ type }: ErrorTemplateProps) => {
  const errorStatus = ERROR_TEXT.find(item => item.type === (type ?? 'error'));

  return (
    <>
      <nav id="nav-closure-error" className="navbar closure">
        <div className="fluid-main">
          <div className="contents-wrap">
            <Link className="navbar-brand" to="#">
              <img src={brandIc} alt="" />
            </Link>
          </div>
        </div>
      </nav>
      <div className="global-container closure">
        <div className="error-col">
          <img src={errorIc} alt="error" />
          <div className="title">
            {errorStatus?.title?.map((title, idx) => (
              <Fragment key={title}>
                {idx !== 0 && <br />}
                {title}
              </Fragment>
            ))}
          </div>
          <div className="sub-title">
            {errorStatus?.content?.map((content, idx) => (
              <Fragment key={content}>
                {idx !== 0 && <br />}
                {content}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
