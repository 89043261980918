import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from '@/constants/auth';
import { PATH } from '@/constants/path';
import { getLocalStorage, getSessionStorage } from '@/utils';
import { useMutationLogout } from '@/apis/friendly-pharmacist/user/useMutationLogout';
import { httpClient } from '@/apis/api';
import toast from 'react-hot-toast';
import { getUserInfo } from '@/apis/friendly-pharmacist/user/user';

export default function useLoginInfo() {
  const navigate = useNavigate();
  const recentViewedPrds = getSessionStorage('rct_p') || null;
  const userToken =
    sessionStorage.getItem(AUTH_TOKEN_KEY) ?? localStorage.getItem(AUTH_TOKEN_KEY) ?? '';
  const refreshToken =
    sessionStorage.getItem(AUTH_REFRESH_TOKEN_KEY) ??
    localStorage.getItem(AUTH_REFRESH_TOKEN_KEY) ??
    '';

  const isLogin = useMemo(() => {
    return !!userToken;
  }, [userToken]);
  const { mutate: mutateLogout } = useMutationLogout({
    onSuccess: async ({ message }) => {
      sessionStorage.removeItem(AUTH_TOKEN_KEY);
      sessionStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
      localStorage.removeItem(AUTH_TOKEN_KEY);
      localStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);

      httpClient.interceptors.response.use(
        config => {
          delete config.headers.Authorization;
          return config;
        },
        error => Promise.reject(error),
      );

      toast.error(message);
      const referrerIndex = getSessionStorage('REFERRER');
      const beforeUrl = location.pathname + location.search;
      const navigateUrl = recentViewedPrds?.[0]?.id
        ? `${PATH.PRODUCT_DETAIL}/${recentViewedPrds[0]?.id}${
            referrerIndex ? `?referrerIdx=${referrerIndex}` : ''
          }`
        : `${PATH.LOGIN}?before_url${beforeUrl}`;
      setTimeout(() => {
        navigate(navigateUrl);
      }, 100);
    },
    onError: (err: any) => {
      console.log('LOGOUT FAILED', err.response);
    },
  });
  const logout = async () => {
    mutateLogout({ refreshToken });
  };

  return { isLogin, userToken, logout };
}
