import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Modal } from '@components/common/molecules/modal/index';
import { Toast } from '@components/common/molecules/toast';

import useFetchCautions from '@/apis/commerce/cautions/useFetchCautions';
import { useMutationOrderCancel } from '@/apis/commerce/orders/useMutationOrderCancel';
import { useFetchOrderSpecs } from '@/apis/commerce/specs/useFetchOrderSpecs';
import closeIc from '@/assets/ic/x-lg.svg';
import {
  // WoOrderBeginExchangePostRes,
  WoOrderBeginRefundPostRes,
  WoOrderOption,
  WoOrderPaymentCancelPostRes,
  WoOrderSpecGetRes,
} from '@/generated/api/type/data-contracts';
import { useLockBodyScroll } from '@/hooks/use-lock-body-scroll';
import { numberFormat } from '@/utils/formats';

import { CancellationOptionListModal } from './cancellation-option-list-modal';
import { LoaderImg } from '../../organism/auth/loader-img';

interface OrderCancellationModalProps {
  optionList?: WoOrderOption[];
  toggleModal: (isSuccess?: boolean) => void;
  type: 'cancel' | 'exchange' | 'refund';
  isOpen: boolean;
}
/**
 * 주문 취소/ 반품 요청 모달
 * @param toggleModal 모달 토글 함수
 * @param selectedOptions 선택된 옵션 리스트
 * @param type 취소/교환/반품 타입 ('cancel' | 'exchange' | 'refund')
 */
export const OrderCancellationModal = ({
  optionList,
  toggleModal,
  type,
  isOpen,
}: OrderCancellationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const selectableOptions =
    type === 'cancel'
      ? optionList?.filter(option => option?.btn_cancel_yn === 'Y')
      : type === 'refund'
      ? optionList?.filter(option => option?.btn_return_yn === 'Y')
      : (optionList as WoOrderOption[]);
  const [selectedOptions, setSelectedOptions] = useState<WoOrderOption[]>([]);
  const [selectedOptionIdxes, setSelectedOptionIdxes] = useState<number[]>([]);
  const { id } = useParams();
  const [selectedReason, setSelectedReason] = useState('cmind');
  const [textBox, setTextBox] = useState('');
  const { mutate: cancelMutate } = useMutationOrderCancel();
  const { data: orderSpecs } = useFetchOrderSpecs({});
  const [returnAmount, setReturnAmount] = useState(0);
  const [status, setStatus] = useState('selection');
  const [toast, setToast] = useState({
    isShow: false,
    msg: '',
    duration: 5000,
    handleClickClose: () => {},
  });
  useEffect(() => {
    const ids = selectedOptions.map(option => option?.order_option_idx) as number[];

    selectedOptions.length ? setSelectedOptionIdxes(ids) : setSelectedOptionIdxes([]);
  }, [selectedOptions]);

  const handleSelectOption = (selected: WoOrderOption) => {
    if (selectedOptionIdxes.includes(selected?.order_option_idx ?? 0)) {
      setSelectedOptions(
        selectedOptions.filter(option => option.product_option_idx !== selected.product_option_idx),
      );
    } else {
      setSelectedOptions([...selectedOptions, selected]);
    }
  };

  const handleOrderCancelClick = () => {
    setIsLoading(true);
    selectedOptionIdxes.length > 0 &&
      cancelMutate(
        {
          type,
          orderIdx: id ?? '',
          cancelReasonCd: selectedReason,
          orderOptions: selectedOptionIdxes,
          requestText: textBox,
        },
        {
          onSuccess: data => {
            if (type === 'refund') {
              const refundData = data as WoOrderBeginRefundPostRes;
              setReturnAmount(refundData?.total_payment_amount ?? 0);
            } else if (type === 'cancel') {
              const cancelData = data as WoOrderPaymentCancelPostRes;
              setReturnAmount(cancelData?.refunded_amount ?? 0);
            }
            setStatus('succeed');
            setIsLoading(false);
          },
          onError: (e: any) => setToast({ ...toast, msg: e?.response?.data, isShow: true }),
        },
      );
  };

  const handleClickReason = (item: string) => {
    setSelectedReason(item);
  };
  const handleChangeTextBox = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length <= 1000) {
      setTextBox(e.currentTarget.value);
    }
  };
  const header = type === 'cancel' ? '주문 취소' : type === 'exchange' ? '교환 요청' : '반품 요청';
  const typeKor = type === 'cancel' ? '취소' : type === 'exchange' ? '교환' : '반품';
  const btnTitle =
    type === 'cancel' ? '취소하기' : type === 'exchange' ? '교환 요청하기' : '반품 요청하기';

  const selectedOptionName =
    orderSpecs?.board_order_request_reason_codes?.find(
      reason => reason?.order_request_reason_cd === selectedReason,
    )?.order_request_reason_name ?? '';

  return toast.isShow ? (
    <Toast message={toast.msg} duration={toast.duration} handleClickClose={toggleModal} />
  ) : status === 'selection' ? (
    <CancellationOptionListModal
      optionList={selectableOptions}
      selectedOptions={selectedOptions}
      selectedIDs={selectedOptionIdxes}
      toggleModal={toggleModal}
      handleSelectOption={handleSelectOption}
      handleButtonClick={() => setStatus('request')}
      isOpen={true}
    />
  ) : status === 'request' ? (
    <>
      {orderSpecs && (
        <Request
          isLoading={isLoading}
          typeKor={typeKor}
          orderSpecs={orderSpecs}
          length={textBox.length}
          selectedReason={selectedReason}
          toggleModal={toggleModal}
          header={header}
          handleClickReason={handleClickReason}
          handleChangeTextBox={handleChangeTextBox}
          btns={[
            {
              title: btnTitle,
              handleClick: () => handleOrderCancelClick(),
              disabled: (!btnTitle.includes('취소') && textBox.length < 10) || isLoading,
            },
          ]}
          isOpen={true}
        />
      )}
    </>
  ) : (
    <Complete
      header={header}
      type={type}
      returnAmount={returnAmount}
      selectedReason={selectedOptionName}
      writtenReason={textBox}
      toggleModal={toggleModal}
      typeKor={typeKor}
      isOpen={true}
    />
  );
};

interface RequestProps {
  isLoading: boolean;
  header: string;
  typeKor: string;
  orderSpecs: WoOrderSpecGetRes;
  length: number;
  selectedReason: string;
  handleClickReason: (item: string) => void;
  handleChangeTextBox: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  toggleModal: () => void;
  btns: Array<{ title: string; handleClick: () => void; disabled?: boolean }>;
  isOpen: boolean;
}

const Request = ({
  isLoading,
  typeKor,
  header,
  length,
  orderSpecs,
  selectedReason,
  btns,
  handleClickReason,
  handleChangeTextBox,
  toggleModal,
  isOpen,
}: RequestProps) => {
  const { data: cancelCautionData } = useFetchCautions({ cautionTypeCd: 'cancel' });
  const { data: returnCautionData } = useFetchCautions({ cautionTypeCd: 'refund' });
  return (
    <Modal toggleModal={toggleModal} title={header} btns={btns} isOpen={isOpen}>
      <div className="row">
        {/* <!-- 취소 사유 --> */}
        <div className="input-group">
          <div className="form-label">{typeKor} 사유</div>
          <ul className="sort-list half-list">
            {orderSpecs?.board_order_request_reason_codes?.map(reason => (
              <li
                onClick={() => handleClickReason(reason?.order_request_reason_cd ?? '')}
                key={reason?.order_request_reason_cd}
              >
                <label className="radio-group green-active-wrap">
                  <input
                    type="radio"
                    name="test-1"
                    onChange={() => {}}
                    checked={reason?.order_request_reason_cd === selectedReason}
                  />
                  <div className="label">
                    <div className="title">{reason?.order_request_reason_name}</div>
                  </div>
                  <span className="checkmark" />
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className="input-group">
          <textarea className="form-control" name="" onChange={handleChangeTextBox} />
          <div className="view-words">
            <span className="value">{length}</span>/1000
          </div>
        </div>
      </div>
      <div className="modal-notice">
        {typeKor === '취소' ? (
          <>
            <div className="title">유의사항</div>
            <ul className="list-group">
              {cancelCautionData?.caution_articles?.map((article, index) => (
                <li key={index} className={index === 0 ? 'notice' : ''}>
                  {article?.c_text}
                </li>
              ))}
            </ul>
          </>
        ) : (
          <>
            <div className="title line">반품/교환 기준 안내</div>
            <ul className="list-group">
              {returnCautionData?.caution_articles?.map((article, index) => (
                <li key={index}>{article?.c_text}</li>
              ))}
            </ul>
          </>
        )}
      </div>
      {isLoading && <LoaderImg wrapperStyles={{}} />}
    </Modal>
  );
};

interface CompleteProps {
  type: 'cancel' | 'exchange' | 'refund';
  typeKor: string;
  returnAmount: number;
  header: string;
  selectedReason: string;
  writtenReason: string;
  toggleModal: (isSuccess?: boolean) => void;
  isOpen: boolean;
}

const Complete = ({
  type,
  typeKor,
  returnAmount,
  header,
  selectedReason,
  writtenReason,
  toggleModal,
  isOpen,
}: CompleteProps) => {
  //TODO: 공통모달컴포넌트로 수정되면 훅 삭제
  useLockBodyScroll(isOpen);

  const title =
    type === 'cancel'
      ? '주문이 취소되었어요'
      : type === 'exchange'
      ? '교환 요청이 접수되었어요'
      : '반품 요청이 접수되었어요';

  const context =
    type === 'cancel'
      ? '환불은 결제 수단별 환불 처리 절차에 따라 다르며,\n영업일 기준 최대 7일 이내 처리됩니다.'
      : type === 'exchange'
      ? '판매자가 이미 상품을 발송한 경우\n교환요청이 철회될수 있어요'
      : '판매자가 이미 상품을 발송한 경우\n반품요청이 철회될수 있어요';

  return (
    <div className="modal-background">
      <div className="modal-fluid">
        {/* <!-- 상단 네비게이션 --> */}
        <div className="modal-fluid-nav">
          <div className="title">{header}</div>
          <Link to="#" className="btn-close" onClick={() => toggleModal(true)}>
            <img src={closeIc} alt="" />
          </Link>
        </div>
        <div className="modal-contents">
          <div className="row">
            <div className="empty-list">
              <div className="lines">
                <span>“{title}”</span>
                <span className="line"></span>
              </div>
              <div className="sub-text">
                {context.split('\n').map((text, index) => (
                  <Fragment key={index}>
                    {index !== 0 && <br />}
                    {text}
                  </Fragment>
                ))}
              </div>
            </div>
            <div className="modal-cancel-info">
              <div className="title">
                <span>{typeKor} 사유</span>
                <span className="category">{selectedReason}</span>
              </div>
              <div className="info">{writtenReason}</div>
            </div>
          </div>
        </div>
        <div className="mobile-bottom-nav">
          {/* <!-- 총 금액 --> */}
          <div className="total-price">
            <div className="label">
              {['cancel', 'refund'].includes(type) ? '환불 예정금액' : '배송 결제금액'}
            </div>
            {/* // TODO: 배송비 결제금액 */}
            <div className="pricing">{numberFormat(returnAmount)}</div>
            <div className="unit">원</div>
          </div>
          <div className="btn-inline-group">
            <button
              type="button"
              className="btn btn-md btn-filled btn-block"
              onClick={() => toggleModal(true)}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
