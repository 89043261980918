import { useParams } from 'react-router-dom';

import { useFetchOrderStatus } from '@/apis/commerce/orders/useFetchOrderStatus';
import { numberFormat } from '@/utils/formats';

export const OrderDetailInfo = () => {
  const { id } = useParams();
  const { data: orderStatusData } = useFetchOrderStatus({
    orderIdx: id ?? '',
    options: { staleTime: 0 },
  });

  return (
    <>
      {/* <!-- 배송정보 --> */}
      <div className="section-contents">
        <div className="section-title with-desktop-btn">
          <div className="label">배송정보</div>
        </div>
        <div className="selected-address">
          <div className="border-wrap">
            <div className="row">
              <div className="label">받는 분</div>
              <div className="text name">{orderStatusData?.order_status?.ship_recipient_name}</div>
              <span className="badge">기본 배송지</span>
            </div>
            <div className="row address">
              <div className="label">배송지</div>
              <div className="text">{orderStatusData?.order_status?.ship_address}</div>
            </div>
            <div className="row">
              <div className="label">연락처</div>
              <div className="text">{orderStatusData?.order_status?.ship_contact1}</div>
            </div>
          </div>
          <div className="row">
            <div className="label">배송 요청사항</div>
            <div className="text">{orderStatusData?.order_status?.ship_memo ?? ''}</div>
          </div>
        </div>
      </div>
      {/* <!-- 결제정보 --> */}
      <div className="section-contents">
        <div className="section-title with-desktop-btn">
          <div className="label">결제정보</div>
        </div>
        <div className="payment-table">
          <div className="row">
            <div className="label">상품금액</div>
            <div className="price">
              {numberFormat(orderStatusData?.order_status?.purchase_order_tot_amount)}
              <span className="unit">원</span>
            </div>
          </div>
          <div className="row">
            <div className="label">할인금액</div>
            <div className="price">
              {orderStatusData?.order_status?.purchase_discount_total
                ? `-${numberFormat(orderStatusData?.order_status?.purchase_discount_total)}`
                : 0}
              <span className="unit">원</span>
            </div>
          </div>
          {!!orderStatusData?.order_status?.purchase_discount_coupon_used_amount &&
            orderStatusData?.order_status?.purchase_discount_coupon_used_amount > 0 && (
              <div className="row sub-row">
                <div className="label">ㄴ 쿠폰 사용</div>
                <div className="price">
                  -
                  {numberFormat(
                    orderStatusData?.order_status?.purchase_discount_coupon_used_amount,
                  )}
                  <span className="unit">원</span>
                </div>
              </div>
            )}
          <div className="row shipping-info">
            <div className="label">배송비</div>
            <div className="price">
              {numberFormat(orderStatusData?.order_status?.purchase_shipping_fee || 0)}
              <span className="unit">원</span>
            </div>
          </div>
          <div className="row total">
            <div className="label">최종 결제금액</div>
            <div className="price">
              {numberFormat(orderStatusData?.order_status?.purchase_total_payment_amount)}
              <span className="unit">원</span>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- 환불정보 --> */}
      {!!orderStatusData?.order_status?.refund_payment_type && (
        <div className="section-contents">
          <div className="section-title with-desktop-btn">
            <div className="label">환불정보</div>
          </div>
          <div className="payment-table">
            <div className="row">
              <div className="label">{orderStatusData?.order_status?.refund_payment_type}</div>
              <div className="price">
                {numberFormat(orderStatusData?.order_status?.refund_total_payment_amount)}
                <span className="unit">원</span>
              </div>
            </div>
            <div className="row">
              <div className="label">반품 배송비</div>
              <div className="price">
                <span className="unit">원</span>
              </div>
            </div>
            {/* <div className="row sub-row">
                  <div className="label">ㄴ 환불금 사용</div>
                  <div className="price">
                    -5000<span className="unit">원</span>
                  </div>
                </div> */}
            <div className="row total">
              <div className="label">최종 환불금액</div>
              <div className="price">
                {numberFormat(orderStatusData?.order_status?.refund_total_payment_amount)}
                <span className="unit">원</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <!-- 교환정보 --> */}
      {/* <div className="section-contents">
              <div className="section-title with-desktop-btn">
                <div className="label">교환정보</div>
              </div>
              <div className="payment-table">
                <div className="row">
                  <div className="label">교환 배송비</div>
                  <div className="price">
                    5,000<span className="unit">원</span>
                  </div>
                </div>
                <div className="row total">
                  <div className="label">최종 환불금액</div>
                  <div className="price">
                    24,000<span className="unit">원</span>
                  </div>
                </div>
              </div>
            </div> */}
    </>
  );
};
