import { useMutation } from '@tanstack/react-query';

import { post } from '@/apis/api';
import { WoOrderCompletePostRes } from '@/generated/api/type/data-contracts';
import { WoOrderPaymentConfirmSuccessPostRes } from '@/generated/api/model';

export function useMutationOrderSuccess() {
  const postOrderSuccess = async (params: WoOrderCompletePostRes) => {
    const res = await post('/api/commerce/v1/orders/confirm-success', params);
    return res?.data as WoOrderPaymentConfirmSuccessPostRes;
  };

  return useMutation({ mutationFn: postOrderSuccess });
}
