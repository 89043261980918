import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { type WoOrderOptionsGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  orderIdx: string;
  options?: QueryOptionsType<WoOrderOptionsGetRes>;
}

export function useFetchOrderDetail({ orderIdx, options }: Props) {
  const getOrderDetail = async () => {
    return await get(`/api/commerce/v1/orders/${orderIdx}`).then(res => res.data);
  };

  return useQuery({
    queryKey: ['useFetchOrderDetail', orderIdx],
    queryFn: getOrderDetail,
    ...options,
  });
}
