import { ChangeEvent } from 'react';
import toast from 'react-hot-toast';

/**
 * 유효성 검사 함수
 * @param {string} type 'email' | 'emoji' | 'special' | 'eng' | 'num
 * @param {string} value
 * @returns
 */
export const validation = (type: string, value: string) => {
  switch (type) {
    case 'email': {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return regex.test(value);
    }
    case 'emoji': {
      const regex = /[\uD83C-\uDBFF\uDC00-\uDFFF]+/g;
      return regex.test(value);
    }
    case 'special': {
      const regex = /[^\w\s가-힣ㄱ-ㅎㅏ-ㅣ]/g;
      return regex.test(value);
    }

    case 'eng': {
      const regex = /[a-zA-Z]/;
      return regex.test(value);
    }
    case 'num': {
      const regex = /\d/;
      return regex.test(value);
    }
    default:
      return false;
  }
};

export const validationImages = (event: ChangeEvent<HTMLInputElement>) => {
  if (event.target.files && event.target.files.length > 0) {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        toast.error('업로드가 불가능한 파일이에요.');
        // 입력값 초기화
        return (event.target.value = '');
      }
      if (file.size >= 10 * 1024 * 1024) {
        toast.error('10MB 미만의 파일만 업로드 가능해요.');
        return (event.target.value = '');
      }
    }
  }
  return event.target.value;
};
