import { useQuery } from '@tanstack/react-query';

import { get } from '@/apis/api';
import { type QueryOptionsType } from '@/generated/api/type/api';
import { WoBoardArticlesGetRes } from '@/generated/api/type/data-contracts';

interface Props {
  qnaBoardTypeCd: string;
  offset: number;
  limit: number;
  options?: QueryOptionsType<WoBoardArticlesGetRes>;
}

export function useFetchInquiries({ qnaBoardTypeCd, offset, limit, options }: Props) {
  const getInquiries = async () => {
    return await get(
      `/api/commerce/v1/boards/${qnaBoardTypeCd}/articles?offset=${offset}&limit=${limit}`,
    ).then(res => res.data as WoBoardArticlesGetRes);
  };

  return useQuery({
    queryKey: ['useFetchInquiries', qnaBoardTypeCd, offset, limit],
    queryFn: getInquiries,
    ...options,
  });
}
