import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useMutationOrder } from '@/apis/commerce/orders/useMutationOrder';
import { useFetchOrderForm } from '@/apis/commerce/preorders/useFetchOrderForm';
import { useFetchOrderOptions } from '@/apis/commerce/preorders/useFetchOrderOptions';

import useMixpanel from '@/hooks/use-mixpanel';
import { useSearchUrl } from '@/hooks/use-search-url';

import { AUTH_TOKEN_KEY } from '@/constants/auth';
import { refreshAccessToken } from '@/apis/friendly-pharmacist/user/user';
import toast from 'react-hot-toast';
import { removeLocalStorage, removeSessionStorage } from '@/utils';
import { PATH, PRIVACY_AGREEMENT_URL } from '@/constants/path';

export const useOrder = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [paymentWidget, setPaymentWidget] = useState<any>(null);
  // const [paymentInstance, setPaymentInstance] = useState<any>(null);
  const [alertState, setAlertState] = useState<{
    isAlertVisible: boolean;
    header: string[];
    body: string[];
    buttons: { title: string; handleClick: () => void; style: string }[];
  }>({
    isAlertVisible: false,
    header: [''],
    body: [''],
    buttons: [{ title: '', handleClick: () => {}, style: 'primary' }],
  });

  const { getStateBySearchParams } = useSearchUrl();
  const preorderId = getStateBySearchParams('id') ?? '';
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const { handleMixpanelEvent } = useMixpanel();

  useEffect(() => {
    handleMixpanelEvent('view_payment', { from: location?.state?.from ?? '' });
  }, [location?.state?.from]);

  // const widgetClientKey = process.env.REACT_APP_TOSSPAYMENTS_KEY ?? '';
  // const customerKey =
  //   userInfoData?.user_info?.id ?? userDetailData?.self_detail?.email ?? 'no_unique_key';

  const { data: orderFormData, refetch: refetchFormData } = useFetchOrderForm({
    preorder_idx: preorderId ?? '',
    options: { staleTime: 0 },
  });
  const { data: orderOptionListData, refetch: refetchOptionList } = useFetchOrderOptions({
    preorder_idx: preorderId ?? '',
    options: { staleTime: 0 },
  });
  const { mutate: mutateOrder } = useMutationOrder();

  useEffect(() => {
    if (!isAddressModalOpen) {
      refetchFormData();
      refetchOptionList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressModalOpen]);

  // const tossPaymentsInit = () => {
  //   const script = document.createElement('script');
  //   script.src = 'https://js.tosspayments.com/v1/payment-widget';
  //   script.async = true;
  //   // 스크립트 로드 완료 후 처리
  //   script.onload = () => {
  //     const tossPayment = window?.PaymentWidget;
  //     if (tossPayment) {
  //       const widget = tossPayment(widgetClientKey, customerKey);
  //       setPaymentWidget(widget); // 상태 업데이트
  //     }
  //   };

  //   // head에 스크립트를 추가
  //   document.head.appendChild(script);

  //   // 컴포넌트 언마운트 시 스크립트 제거
  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // };

  // useLayoutEffect(() => {
  //   tossPaymentsInit();
  // }, [widgetClientKey, customerKey]);

  // useLayoutEffect(() => {
  //   const renderWidget = async () => {
  //     if (paymentWidget && orderFormData?.order) {
  //       // renderPaymentMethods의 반환값(인스턴스)를 paymentInstance에 저장
  //       const instance = await paymentWidget.renderPaymentMethods(
  //         '#payment-method',
  //         {
  //           value: orderFormData?.order?.total_payment_amount,
  //           currency: 'KRW',
  //           country: 'KR',
  //         },
  //         { variantKey: 'DEFAULT' },
  //       );
  //       setPaymentInstance(instance);
  //     }
  //   };
  //   renderWidget();
  // }, [paymentWidget, orderFormData]);

  // // 뒤로가기(POP) 시 위젯 제거(destroy) 처리
  // useEffect(() => {
  //   console.log(paymentInstance);
  //   if (navigationType === 'POP' && paymentInstance) {
  //     (async () => {
  //       await paymentInstance.destroy();
  //       setPaymentInstance(null);
  //     })();
  //   }
  // }, [navigationType, paymentInstance]);

  const closeAlert = () =>
    setAlertState({
      isAlertVisible: false,
      header: [''],
      body: [''],
      buttons: [{ title: '', handleClick: () => {}, style: 'primary' }],
    });

  const handleClickOrder = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!orderFormData?.order?.ship_address) {
      return handleAddressModalOpen();
    } else if (!orderFormData?.order?.ship_contact1) {
      setAlertState({
        isAlertVisible: true,
        header: [''],
        body: ['연락처를 입력해 주세요.'],
        buttons: [
          {
            title: '확인',
            handleClick: () => {
              closeAlert();
              handleAddressModalOpen();
            },
            style: 'primary',
          },
        ],
      });
      return;
    } else {
      try {
        const localStorageToken = localStorage.getItem(AUTH_TOKEN_KEY);

        const newTokenData = await refreshAccessToken();

        localStorageToken
          ? localStorage.setItem(AUTH_TOKEN_KEY, newTokenData?.data?.access_token)
          : sessionStorage.setItem(AUTH_TOKEN_KEY, newTokenData?.data?.access_token);

        handleMixpanelEvent('click_purchase_product_store', {});
        mutateOrder(preorderId, {
          onSuccess: data => {
            if (data?.order_id) {
              paymentWidget.requestPayment({
                orderId: data?.order_id,
                orderName: orderFormData?.order?.order_name,
                successUrl: `${window.location.origin}${PATH.ORDER_SUCCESS}/${preorderId}`,
                failUrl: window.location.origin + `${PATH.ORDER}?id=${preorderId}`,
                // customerEmail: 'customer123@gmail.com',
                // customerName: '김토스',
                // customerMobilePhone: '01012341234',
              });
            } else {
              console.log(data);
            }
          },
          onError: err => {
            console.log(err);
          },
        });
      } catch (e: any) {
        if (e?.response?.status === 401) {
          removeSessionStorage(AUTH_TOKEN_KEY);
          removeLocalStorage(AUTH_TOKEN_KEY);
          const beforeUrl = location.pathname + location.search;
          toast.error('로그인이 만료되었습니다.\n 다시 로그인 해주세요.');
          navigate(`${PATH.LOGIN}?before_url=${encodeURIComponent(beforeUrl)}`);
        }
      }
    }
  };
  const handleAddressModalOpen = () => setIsAddressModalOpen(true);
  const handleAddressModalClose = () => setIsAddressModalOpen(false);

  const handleClickDetail = () => {
    window.open(PRIVACY_AGREEMENT_URL, '_blank')?.focus();
  };

  return {
    orderOptionListData,
    isAddressModalOpen,
    alertState,
    orderFormData,
    paymentWidget,
    setPaymentWidget,
    handleAddressModalClose,
    handleAddressModalOpen,
    handleClickDetail,
    handleClickOrder,
  };
};
