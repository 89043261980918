import Lottie from 'react-lottie';
import { Link, useNavigate } from 'react-router-dom';
import brandIc from '@/assets/ic/brand-logo-wt.svg';
import errorImg from '@/assets/img/error-img.json';
import { setSessionStorage } from '@/utils';
import { useEffect } from 'react';
import { ORDER_FAILED_KEY } from '@/constants/order';
import { PATH } from '@/constants/path';
import { useSearchUrl } from '@/hooks/use-search-url';

export const OrderFailedTemplate = () => {
  const navigate = useNavigate();
  const { getStateBySearchParams } = useSearchUrl();
  const preorderId = getStateBySearchParams('id') ?? '';

  const handleButtonClick = () => {
    navigate(preorderId ? `${PATH.ORDER}?id=${preorderId}` : PATH.MYPAGE);
  };
  useEffect(() => {
    setSessionStorage(ORDER_FAILED_KEY, 'true');
  }, []);
  return (
    <>
      <nav id="nav-closure-error" className="navbar closure">
        <div className="fluid-main">
          <div className="contents-wrap">
            <Link className="navbar-brand" to="#" onClick={e => e.preventDefault()}>
              <img src={brandIc} alt="" />
            </Link>
          </div>
        </div>
      </nav>
      <div className="global-container closure">
        <div className="error-col">
          <div className="img">
            <Lottie
              options={{
                loop: true,
                // Autoplay: true,
                animationData: errorImg,
              }}
              width={100}
              height={100}
            />
          </div>

          <div className="title">
            <span>주문이 정상적으로</span>
            <br />
            <span>이루어지지 않았어요</span>
          </div>
          <div className="sub-title">이용에 불편을 드려 죄송합니다.</div>
          <button
            type="button"
            className="btn btn-lg btn-outline btn-block"
            onClick={handleButtonClick}
          >
            뒤로가기
          </button>
        </div>
      </div>
    </>
  );
};
