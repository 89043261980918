import emailIc from '@/assets/ic/email.svg';
import appleIc from '@/assets/ic/apple-circle.svg';
import kakaoIc from '@/assets/ic/kakao.svg';

interface UserInfoItemProps {
  name: string;
  value: string;
  accountType?: string;
  button?: { title: string; handleClick: () => void };
}
export const UserInfoItem = ({ name, value, accountType, button }: UserInfoItemProps) => {
  return (
    <li className="myInfo-forms-item">
      <div className="label">{name}</div>
      <div className={`input-group${name === '이메일' ? '' : ' inline nickname'}`}>
        {name === '이메일' && accountType && (
          <img
            src={accountType === 'apple' ? appleIc : accountType === 'kakao' ? kakaoIc : emailIc}
            alt="email"
          />
        )}
        <div className="text no-link">{value}</div>
        {button && (
          <button
            type="button"
            className="btn btn-sm btn-outline"
            name="nickname"
            onClick={button.handleClick}
          >
            {button.title}
          </button>
        )}
      </div>
    </li>
  );
};
