import { post } from '@/apis/api';
import { useMutation } from '@tanstack/react-query';

export const useMutationRegisterKakao = () => {
  const register = async ({
    kakao_access_token,
    agreements,
  }: {
    kakao_access_token: string;
    agreements: {
      sms: boolean;
      email: boolean;
      push: boolean;
    };
  }) => {
    const response = await post('/user/signup/social/store-kakao', {
      kakao_access_token,
      agreements,
    });
    return { response: response.data };
  };

  return useMutation({
    mutationFn: register,
  });
};
